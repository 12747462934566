@font-face {
    font-family: 'Yekan';
    font-style: normal;
    font-weight: normal;
    src: local('Yekan'), local('Yekan'), url('/ZCDNURL/fonts/Yekan.woff') format('woff');
}

html.fa {
    .fa body {
        background-image: none;
        line-height: 1em;
    }
    .fa {
        body, html, button, input, select, textarea, .modal-small .question, .mob, .getmorespace, .gfrespace, .mob, 
        .tabs .ctrl span b, ul.mn a, .spacen, .getmorespace, .spacen, .mnheader.cheader_up, .fontcng, .footer .copyright, 
        .footer_mini, .modal .processing .information, .modal .loadcenter, .lightbox .loadcenter, 
        .modal-small .question, .lightbox .gtitle, .darkbox .dark-toolbar .dark-title, .upload .tabcontrols .xmethods span, 
        .uploadtitle, .minimizedtitle, .upload .status .file span, .pupload .pupstatus .file span, 
        .upload .status .file .percent, .pupload .pupstatus .file .percent, .upload .status .file .total, .pupload .pupstatus .file .total, 
        .upload .file span.cancel, .upload .file span.remove, .pupload .file span.cancel, .pupload .file span.remove, 
        .upload_minimized .activedownloads, .upload_minimized .minperc, .message, .publinkdownload, .tooltip-inner, .tutorials-header, 
        .sharepublinknew .tomails .combo-contain .combo-wrap input, .share-add .tomails .combo-contain .combo-wrap input {
            font-family: "Yekan", Arial, Helvetica !important;
        }
    }

    .header {
        .logo {
            float: right;
            margin-left: 19px;
            padding: 13px 20px 12px 0px;
            border-left: 1px solid #1D1E1E;
            border-right: medium none;
        }
        .centr {
            margin-left: 0;
            input {
                float: right;
                padding-left: 0;
                padding-right: 9px;
            }
            .search-button {
                padding-left: 6px;
                padding-right: 9px;
                border-left-style: solid;
                border-right-style: none !important;
            }
        }
        .profile {
            float: left;
            .username {
                margin-left: 7px;
                margin-right: 0;
            }
            .settings {
                margin-left: 12px;
                margin-right: 3px;
            }
        }
    }

    .main {
        padding: 0 20px 60px 15px;
        margin-left: 0;
        margin-right: 210px;
    }

    .menu {
        padding-left: 0;
        padding-right: 20px;
        ul {
            margin: 6px 0 6px 0;
            li {
                img {
                    margin: -3px auto auto 10px;
                }
                span {
                    background: url(/ZCDNURL/img/crypto/new_fa.png) no-repeat left center transparent !important;
                    padding: 0 10px 0 0 !important;
                    margin: -2px 0 0 0;
                }
            }
            &.subul {
                padding: 0 25px 0 0;
                .subul-left-border {
                    left: auto;
                    right: 9px;
                }
                .subul-left-border-horizontal {
                    left: auto;
                    right: -15px;
                }
            }
        }
    }

    .linebut {
        margin-left: 5px;
        margin-right: 0;
    }
    .newfolder {
        float: right;
        img {
            margin: -4px -2px auto 8px;
        }
    }
    .fa {
        .main {
            border-right: 1px solid #efefef;
        }
        .download-clients {
            left: auto;
            right: 19px;
        }
        .download-clients .platform img {
            float: right;
            margin-left: 10px;
            margin-right: 0;
        }
        .download-clients .platform span {
            float: right;
        }
        div.comments {
            margin-left: 0;
            margin-right: 10px;
        }
        .menu ul li.b_business {
            background: url('/img/open-rtl.png') no-repeat left 21px top 8px;
        }
        .modal {
            .edit_header_form {
                label {
                    width: inherit;
                }
                input {
                    margin: 0 89px 10px 10px;
                }
                input.bgcolor.is-color {
                    cursor: pointer;
                    margin: 0 97px 10px 0;
                }
                .hash_sign, .hash_explain {
                    display: inline-block;
                }
            }
        }
        .modal .change_bg_title .modal-title-text {
            float: none;
            text-indent: 0 !important;
        }
        .upload .status .file .total, .pupload .pupstatus .file .total {
            white-space: nowrap;
        }
        td.file span.filename, td.share span.filename {
            direction: ltr;
        }
        div.cheader {
            div.fbreadcrumb {
                float: right !important;
                padding: 5px 3px 4px 0;
                span {
                    direction: ltr;
                }
            }
            div.controls {
                float: left;
                .button.linebut {
                    float: right;
                }
                .smallbut {
                    float: right;
                }
            }
            .cheader_up {
                margin-left: 0;
                margin-right: 5px;

            }
        }
        div.headerunder {
            padding-left: 0;
            padding-right: 10px;
            z-index: 100;
            div.controlstitle {
                float: right;
            }
            div.gridmanage {
                float: left;
                padding-left: 10px;
                padding-right: 0;
                ul.templateswitch {
                    float: right;
                    li {
                        float: right;
                    }
                }
                .csortnew {
                    margin-left: 0;
                    margin-right: 10px;
                }
            }
            div.controlsplc {
                float: right;
                margin-left: 0;
                margin-right: 5px;
            }
            div.ctrlcell, div.ctrlcellmore {
                float: right;
            }
        }
        .csort ul, .csortnew ul {
            left: -1px;
            right: auto;
        }
        .csortnew ul {
            a {
                padding-left: 25px;
                padding-right: 15px;
                li img {
                    margin-left: 8px;
                }
            }
            li input {
                margin: 0px 3px 0px 7px;
            }
        }
        div.infocell {
            padding: 0 5px 0 15px;
            direction: ltr;
        }
        div.ctrlcell span {
            margin: 9px 0 0 3px;
            padding: 0 0 0 6px;
        }
        div.ctrlcellmore span {
            margin: 9px 0 0 3px;
            padding: 0 6px 0 0;        
        }
        div.ctrlcell img {
            margin: -1px 6px 0 6px;
        }
        .share-opts {
            float: left;
            margin-left: 5px;
            margin-right: 0;
        }
        .share-opts img {
            margin-left: 0;
            margin-right: 9px;
        }

        .mnnew a {
            li {
                img {
                    margin-left: 5px;
                    margin-right: 0 !important;
                }
            }
            &.sub {
                img {
                    float: left;
                }
            }
        }

        .mnnew.fileopts a {
            padding-left: 25px;
            padding-right: 15px;
            img {
                float: none;
                margin-left: 7px;
            }
        }
        .share-opts.down-only .download-chain {
            margin-left: -2px;
            margin-right: 7px;
        }
        .mnew-heighter a {
        padding: 13px 14px 13px 15px;
        }
        .mnew-heighter a li img {
            float: right;
            margin-left: 13px;
        }
        .gridlist td, .gridlist th {
            font-family: "Yekan", Arial, Helvetica;
            padding-left: 0;
            padding-right: 10px;
        }
        .gridlist th {
            text-align: right;
        }
        .gridlist td.editbox input, .gridlist th.editbox input {
                margin: 0 6px 0 7px;
        }
        th span.sort {
            float: left;
            margin: 10px 10px 0 8px;
        }
        td.file, th.name {
            border-left: none;
            border-right: none;
            padding-right: 0;
        }
        td.file .publink, td.file .puplink {
            float: left;
            margin: 4px -9px 0 4px;
        }
        div.iconwrap {
            margin: 0 2px 0 7px;
        }
        td.file div.gear {
            margin: 0 5px 0 0;
        }
        .spacen {
            font-family: "Yekan", Arial, Helvetica;
            left: auto;
            right: 0;
            font-size: 11px;
        }
        ul.folderlist {
    //        padding: 0px 15px 0px 0;
            padding-left: 5px;
            width: 100%;
        }
        ul.folderlist ul {
            margin: 2px 25px 2px 2px;
            clear: both;
        }
        ul.folderlist li {
            float: right;
        }
        ul.folderlist li li {
            clear: both;
        }
        ul.folderlist li.children {
            float: none;
        }
        .fprev {
            float: right;
            margin-bottom: 0;
            margin: 1px 3px 1px 0;
        }
        img.listicon {
            margin: 0px 2px 0px 7px;
        }
        .tabs .ctrl span img {
            margin: -4px 4px 0 7px;
        }
        div.headerunder .search {
            float: left;
            margin-left: 8px;
            margin-right: 0;
        }
        div.headerunder .search input {
            background-position: left center;
            padding-left: 0;
            padding-right: 8px;
        }
        label.inv span.lb-title {
            float: right;
        }
        label.inv .import {
            float: left;
            padding-left: 2px;
            padding-right: 0;
        }
        .share-add-holder .share-add-perm .perm_box {
            padding: 5px 11px 5px 0;
        }
        .share-collaborators-list .share-entity .entity-permission .perm_box {
            padding: 0 11px 0 0;
        }
        .share-add-holder .share-add-perm {
            top: 0px;
            left: 43px;
            right: auto;
        }
        .share-add-holder .share-add-perm > span img {
            margin-left: 8px;
            margin-right: 8px;
        }
        .share-footer .closeall {
            float: right;
        }
        .share-footer .rightbut {
            float: left;
        }
        .share-footer .linebut {
        margin-left: 0;
        margin-right: 5px;
        }
        .suggestbox > div.suggestion .iconw {
            float: right;
            margin-left: 6px;
            margin-right: 0;
        }
        .suggestbox > div.suggestion .textpart {
            float: right;
        }
        .combo-wrap {
            float: right;
        }
        .combo-res {
            margin: 0 0 5px 5px;
        }
        .combo-res .rem {
            margin-left: 0;
            margin-right: 4px;
        }
        .share-add .tomails .combo-contain .combo-res .inf {
            margin: -1px 0 0 5px;
        }
        .share-add .expand div {
            text-align: left !important;
        }
        .share-add .expand div .send-share-but {
            text-align: center !important;
        }
        .share-add .expand div .cancel-butt {
            margin-right: 5px;
            margin-left: 0;
            text-align: center !important;
        }

        .share-collaborators {
            .share-collaborators-list {
                .share-entity {
                    text-align: right;
                }
                .share-entity > div {
                    text-align: right;
                }
            }
        }

        .share-collaborators-list .share-entity .entity-remove {
            float: left;
        }
        .share-collaborators-list .share-entity .entity-name img {
            margin-left: 5px;
            margin-right: 0;
        }

        .share-add-holder .share-add-perm-info {
            left: 0;
            right: auto;
        }

        .modal .alert .alert-text {
            text-align: right;
        }

        .main.noleft {
            margin: 0;
            border-right: 1px solid #efefef;
        }
        .register-fields {
            input {
                direction: ltr;
            }
        }
        #rmbme {
            padding: 0 28px 0 0;
            float: right;
        }
        input[type=checkbox].css-checkbox + label.css-label {
            background-position: right 0;
            padding: 0 28px 0 0;
        }
        input[type=checkbox].css-checkbox:checked + label.css-label {
            background-position: right -18px;
        }
        .forgotpass {
            float: left;
        }
        .steps-header {
            h3 {
                float: left;
                width: 80%;
            }
            p {
                float: left;
                clear: both;
                margin-top: 15px;
            }
        }
        .fa .steps li div {
            float: left;
        }
        .steps li span.content {
            margin-left: 0;
            margin-right: 10px;
        }
        .steps li span.progress {
            float: left;
            padding-left: 10px;
        }

        div.gridlist div.gear {
            left: 0;
            right: auto;
        }
        .rginfo .cooltxt {
            padding-right: 15px;
        }
        .darkbox .dark-toolbar .navigation div.current {
            direction: rtl;
        }
    }

    .upload .minimize, .minimizedtitle .maximize {
        left: 27px;
        right: auto;
    }
    .upload .close, .minimizedtitle .close {
        left: 7px;
        right: auto;
    }
    .upload .uploadtitle .minimize {
        left: 9px;
        right: auto;
    }
    .upload_minimized .activedownloads {
        float: right;
        margin-right: 6px;
    }
    .tabs .ctrl span {
        float: right;
        margin-left: 5px;
        margin-right: 0;
    }
    .upload .tabcontrols .xmethods {
        left: 20px;
        right: auto;
        padding-left: 4px;
        padding-right: 0;
    }
    .tabs .ctrl span img {
    margin-left: 4px;
    }
    .fa .cwrap, .fa .openorig {
        text-align: right;
    }

    .getmorespace {
        background-position: 133px center !important;
        text-align: right;
    }

    .upload .status .file span, .pupload .pupstatus .file span {
        padding-left: 70px;
        padding-right: 0;
    }
    .fa .upload .status .file .explain span {
        padding-left: 0;
    }
    .business td.lft {
        text-align: right !important;
    }
    .business table.adminsettings td.name {
        text-align: right;
    }
    .business table.adminsettings td label.off, .business table.adminsettings td label.on {
        text-align: right;
    }
    .fa .notifylist-text {
        padding: 12px 0 0 10px;
        text-align: right;
    }
    .fa {
        .modal {
            text-align: right;
        }
        .initzip .children .crt {
            input {
                text-align: left;
            }
            .button.greenbut {
                float: right;
            }
        }
    }
    .business table.adminsettings td.name .arrowright {
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: "FlipH";
    }
    .business .bteam td.teamact.teamusers .teamacts {
        max-width: none;
    }
    .upload .file span.cancel, .upload .file span.remove, .pupload .file span.cancel, .pupload .file span.remove {
        left: 3px;
        background-position: top left;
        right: auto;
        padding-left: 20px;
        width: 80px;
        text-align: left;
        padding-right: 0;
    }
    .upload .status .file .percent, .pupload .pupstatus .file .percent {
        float: right;
        width: 80%;
    }
    .upload .status .file .total, .pupload .pupstatus .file .total {
        font-family: "Yekan", Arial, Helvetica;
        float: left;
    }

    .modal-title {
        padding-left: 0;
        padding-right: 10px;
    }
    .modal .modal-close, .lightbox .modal-close {
        left: 8px;
        right: auto;
    }
    .modal .tblines td img {
        margin-left: 4px;
        margin-right: 0;
    }

    .fa {
        .sharebutton, .dataandshortlink .sharebutton {
            margin-left: 0;
            margin-right: 4px;
        }
        .puplink-view .stats-wrap .stat {
            float: right;

            img {
                float: right;
                margin-left: 15px;
                margin-right: 0;
            }

            .val {
                float: right;
            }
        }

        .puplink-view-footer {
            .switch {
                float: right;

                img {
                    margin: -1px 0 0 5px;
                    -moz-transform: scaleX(-1);
                    -o-transform: scaleX(-1);
                    -webkit-transform: scaleX(-1);
                    transform: scaleX(-1);
                    filter: FlipH;
                    -ms-filter: "FlipH";
                }         
            }
            .share {
                float: left;

                & > span {
                    margin-left: 5px;
                    margin-right: 0;
                }
            }
            .delete {
                float: left;
            }
        }

        .editing .puplink-edit {
            textarea {
                line-height: 14px;
            }
        }

        .puplink-edit .limits-wrap tr td:first-child input {
            margin: 0 0 0 5px;
        }
    }

    .fa {
        ul.folderlist li .ctrl {
            font-family: Arial, Helvetica;
            margin-left: 7px;
            margin-right: -15px;
        }
        ul.folderlist .ftitle>img {
            margin-left: 5px;
            margin-right: 0;
        }
        .smshare {
            padding: 0 0 0 25px;
            img {
                margin-left: 15px !important;
                margin-right: 8px !important;  
            }
        }
        div.cheader div.fbreadcrumb {
            span+img {
                -moz-transform: scaleX(-1);
                -o-transform: scaleX(-1);
                -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
                filter: FlipH;
                -ms-filter: "FlipH";
            }        
        }
        .fbreadcrumb span div.gear {
            margin: 2px 7px 1px 0;
            float: left;
            .imggear {
                margin: -1px 5px 5px 0;
            }
        }
        div.gear img.imggear {
            margin-top: -3px;
        }
        .gridlist tr.afinfo, .gridlist tr.afinfo td {
            border-right: none;
        }
        #header-send-to {
            float: right;
            text-align: right;    
        }
        #header-status {
            float: left;
            text-align: left;
        }
        .zls-small.zls-btnmn.zlsrotate_rht {
            right: auto !important;
            left: -62px;
            direction: ltr;
        }
        .zls-sptwndw.zlsps-rht.zls-icosw-1 {
            left: 0;
            right: auto;
            direction: ltr;
        }
        .upgrade {
            float: left;
            margin-left: 35px;
            margin-right: 0;
            background: rgb(136,202,0) url('/img/btn_plus_farsi.png') no-repeat right center;
        }
    }
    .sharepublinknew .sharebutton, .dataandshortlink .sharebutton {
        margin-left: 0;
        margin-right: 4px;
    }
    div.gridbox {
        float: right;
        margin: 0 0 10px 10px;
    }
    div.gridbox input.editchk {
        left: auto;
        right: 5px; 
    }
    div.gridbox div.gear {
        left: 5px;
        right: auto;
    }
    .audio-maximized ul.playlist li .remove {
        float: left;
        margin-left: 15px;
        margin-right: 0;
    }
    .audio-maximized ul.playlist .iconwrap {
        margin-left: 10px;
        margin-right: 0;
    }
    .audio-minimized {
        .titlemin {
            img {
                float: right;
                margin-left: 6px;
                margin-right: 0;
            }
            .songname {
                float: right;
            }
        }

    }
    .fa {
        div.albumbox .albcount {
            left: 0;
            right: auto;
        }
        div.albumbox {
            float: right;
        }
    }

    .darkbox .dark-close, .darkboxmob .dark-close {
        left: 0;
        right: auto;
    }
    .darkbox .dark-toolbar .dark-title {
        float: right;
        padding-left: 0;
        padding-right: 10px;
    }
    .darkbox .dark-toolbar .options {
        float: left;
        .opts, .share, .download {
            float: left;
        }
    }
    .darkbox .dark-title img, .darkboxmob .dark-title img {
        margin-left: 3px;
        margin-right: 0;
    }

    .fa {
        .backupacts {
            input {
                margin: 0 5px 0 0;
            }
        }
        .gridlist {
            td.backupacts {
                padding: 0px 5px 0px 10px !important;
                border-left: none;
            }
        }
        .gridlist td:first-child, .gridlist th:first-child {
            border-right: none;
        }
        .gridlist td:last-child, .gridlist th:last-child {
            border-left: none;
            border-right: 1px solid #E9E9E9;
        }
        .sharemail img {
            margin-left: 10px;
            margin-right: 0;
        }
        .permissions label {
            margin-left: 10px;
            margin-right: 0;
        }
        .gridlist td>a {
            float: right;
        }
        .blogs .gridlist td>a {
            float: none;
            margin-right: 5px;
        }
        td.linkItem .iconwrap {
            margin-left: 10px;
            margin-right: 0;
        }
        .history_p {
            .gridlist td:first-child, .gridlist th:first-child {
                border-left: 1px solid #E9E9E9;
            }
        }
        td.histdata {
            padding-left: 0;
            padding-right: 5px;
        }
        td.editbox input, th.editbox input {
            margin: 0 6px 0 7px;
        }
        div.footing .nav .prev {
            float: right;
            -moz-transform: scaleX(-1);
            -o-transform: scaleX(-1);
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        div.footing .nav .next {
            float: left;
            -moz-transform: scaleX(-1);
            -o-transform: scaleX(-1);
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        .dwlbutton {
            float: right;
        }
        .space-header {
            background-position: left center;
            padding-left: 150px;
            padding-right: 0;
        }
        .space-header p {
            margin-top: -10px;
        }
        .space-list img {
        float: right;
        }
        .space-list h5 {
            float: right;
            margin-left: 0;
            margin-right: 20px;
        }
        .space-list .help-text {
            margin-top: 9px;
            margin-left: 0;
            margin-right: 20px;
            float: right;
        }
        .space-note {
            float: right;
            margin-left: 0;
            margin-right: 20px;
        }
        .space-list .space-size {
            float: left;
            margin-top: -18px;
            margin-left: 10px;
            margin-right: 0;
        }
        .invite-form input {
            padding-left: 0;
            padding-right: 10px;
        }
    }
    ul.darkmn a {
        padding-right: 10px;
        padding-left: 0;
    }
    ul.darkmn a li img {
        margin-left: 7px;
        margin-right: 0;
    }
    .modernbut img {
        margin-left: 0;
        margin-right: -10px;
    }

    .darkbox .dark-toolbar .navigation {
        direction: ltr;
    }
    .darkbox .dark-topbar .slideshow-ctrl input.but {
        margin-left: 0;
        margin-right: 7px;
    }
    .createshortlink .generateshortlinkbut {
        margin-left: 25px;
    }
    .dataandshortlink .data .clearfix {
        div {
            float: right;
        }
        img {
            margin: 5px 0 0 10px;
            float: right;
        }
    }
    .backtofilesbut img {
        margin-left: 4px;
        margin-right: 0;
    }
    .help-main {
        direction: ltr;
    }
    .darkbox .dark-toolbar .options {
        padding-left: 10px;
        padding-right: 0;
    }
    .darkbox-opts .tip {
        left: 23px;
        right: auto;
    }
    /*** BUSINESS PAGE ***/
    .choicewrap {
        .shareoutside, .sharepublink, .sharepuplink, .sharebusiness {
            float: right;
        }
    }
    input.textf {
        text-align: right;
    }
    .business-account .entity .ehead .info {
        float: right;
        img {
            margin-left: 7px;
            margin-right: 0;
        }
    }
    .business-account .entity .ehead .controls {
        float: left;
        margin: 8px 0 0 5px;
    }
    .business-account .entity .permissions {
        margin: 8px 0px 2px 30px;
        label {
            margin: 0px 0px 0px 6px;
            text-align: right;
        }
        input {
            margin-left: 5px;
            margin-right: 0;
        }
    }
    .business-account .entity .ehead .controls img {
        margin-left: 0;
        margin-right: 7px;
    }
    .business-account tr.comments .commentsinfo .showing {
        float: right;
    }
    .business-account tr.comments .comment .commhead .userinfo {
        float: right;
        img {
            margin-left: 10px;
            margin-right: 0;
        }
    }
    .business-account tr.comments .comment .commhead .commentinfo {
        float: left;
        img {
            margin-left: 0;
            margin-right: 5px;
        }
    }
    .menu li .badge {
        left: 12px;
        right: auto;
    }
    // .businesstabs .tabs .ctrl {
    //     padding-left: 0;
    //     padding-right: 10px;
    // }
    .business .bheading {
        padding-left: 0;
        padding-right: 10px; 
    }
    .business .table td {
        padding: 0 10px 0 0;
    }
    .business .company-info tfoot td {
        text-align: right !important;
    }
    .business table.share-settings td {
        padding: 20px 0;
    }
    .btn-group>.btn, .btn-group-vertical>.btn {
        float: right;
    }
    .btn-on {
        border-top-left-radius: 0;  
        border-top-right-radius: 20px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 20px;
    }
    .btn-off {
        border-top-left-radius: 20px;  
        border-top-right-radius: 0;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 0;
    }
    .btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    .business .share-settings .form-vertical {
        margin: 5px 20px 10px 0;
        label {
            text-align: right;
            input {
                margin: 0 0 0 5px;
            }
        }
    }
    .business .crteam, .business .invusers {
        background-position: 147px center;
        float: left;
    }
    .business .crteam {
        background-position: 92px center;
    }
    .business .userfilters {
        float: right;
    }
    .business .teamsearch {
        float: left;
        margin-left: 8px;
        margin-right: 0;
        input {
            background-position: left center;
            padding-left: 0;
            padding-right: 8px;
        }
    }
    .combocheck {
        margin-left: 10px;
        margin-right: 0;
    }
    .business .userfilters .bustitle {
        margin-left: 20px;
        margin-right: 0;
    }
    .business .filter_perm, .business .filter_teams {
        margin-left: 10px;
        margin-right: 0;
    }
    .combocheck .title {
        margin-left: 0;
        margin-right: 10px;
    }
    .combocheck .trigger {
        left: 2px;
        right: auto;
        padding-left: 8px;
        padding-right: 0;
        text-align: left;
    }
    .business .filter_status {
        margin-left: 0;
        margin-right: 3px;
    }
    .business .teamacts img {
        margin-left: 10px;
        margin-right: 0;
    }
    .busers .gridline .teamact {
        padding: 0;
    }
    .business .bteams td.file, .business .busers td.file, .business .bteam td.file, .business .blogs td.file, .business .bteams th.name, .business .busers th.name, .business .bteam th.name, .business .blogs th.name {
        padding-left: 0;
        padding-right: 10px;
    }
    .business .filter_status.teams {
        float: left;
        margin-left: 15px;
    }
    .business .logheader .filter_dt {
        float: left;
    }
    .business .logheader .filter_title {
        float: left;
        margin-left: 0;
        margin-right: 10px;
    }
    .business .logheader .filter_entity {
        float: left;
    }
    .business .logheader .filter_go {
        float: left;
        margin-left: 3px;
        margin-right: 10px;
    }
    .ui-datepicker .ui-datepicker-prev {
        left: auto;
        right: 2px;
    }
    .ui-datepicker .ui-datepicker-next {
        left: 2px;
        right: auto;
    }
    .ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
        float: left;
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: "FlipH";
    }
    .business .logheader .filter_dt input {
        background-position: 5px 6px;
        margin-left: 10px;
        margin-right: 5px;
        padding: 2px 4px 2px 21px;
    }
    .quests .itm {
        padding-left: 0;
        padding-right: 10px;
    }
    .quests .icon {
        float: left;
    }
    .quests .itm h4 span {
        padding: 0px 0px 0px 4px;
    }
    .fa .faq .icon {
        float: left;
    }
    .fa .quests .cont {
        margin-left: 40px;
        margin-right: 0;
        padding-left: 0;
        padding-right: 20px;
    }

    .footer .change_lang img {
        margin-left: 0;
        margin-right: 4px;
    }
    .footer .copyright .social {
        margin-left: 0;
        margin-right: 15px;
    }
    .fa .modal-title .modal-title-text/*, .fa .modal label*/ {
        float: right;
        margin-left: 35px;
        margin-right: 0;
        direction: rtl;

        a {
            padding: 0 4px 0 0;
            display: inline-block;
        }
    }
    .fa .modal label {
        text-align: right;
        width: 100%;
    }

    .fa .modal .sharepublinknew label:first-child {
        float: right;
        width: auto;
    }

    .fa {
    .notify-icon {
            float: left;
            margin-right: 0;
            margin-left: 30px;
    }
    .modal-files {
            width: 100%
    }
    .progresszip, .shortlinkexplain {
            text-align: right;
    }
    }

    .fa .notifylist-arrow {
        float: left;
        margin-left: 13px;
    }
    .fa {
        .popover-content {
            direction: rtl;
            text-align: right;
        }
    }
    .fa {
        td.ver div.ver {
            direction: ltr; 
        }
        .modal .sharetbl td {
            text-align: right;
        }
        .gridlist td.gridsize {
            padding-left: 10px;
            padding-right: 10px;
        }
        .folderlist li div.crt {
            clear: both;
        }
        .folderlist li div.button.greenbut.modernbut.linebut.shortie {
        margin-left: 0;
        margin-right: 5px;
        }
    }
    .fa .setbl td.rght {
        text-align: left !important;
    }
    .fa {
        .acceptshare {
            td {
                text-align: right;
                label {
                    float: right;
                }
            }
            .tomail td img, .frommail td img, .shareicon img {
                margin-left: 4px;
                margin-right: 0;
            }
            .yesbut {
                margin-left: 5px;
                margin-right: 0;
            }
        }
        .modal .tblines td b {
            margin: 0 0 0 25px;
        }
    }
    .fa {
        .accountwrap div .setbl tr th {
            text-align: right;
        }
        #invites li.accepted span, #invites li.pending span {
            float: left;
        }
        #invites li img {
            margin-left: 10px;
            margin-right: 0;
        }
        .form-horizontal label {
            margin-left: 0;
            margin-right: 20px;
        }
        textarea.tarea {
            overflow: hidden;
        }

        .businessmodal.modal .modal-title .modal-title-text {
            text-indent: 20px;
        }

        .myshares-tab {
            .actions {
                padding-left: 10px;
                .share-opts {
                    float: none;
                    margin-left: 8px;
                    margin-right: 0;
                }
                div.stop {
                    float: none;
                }
            }
            .summary-invites {
                padding-right: 0;
                text-align: left;
            }
        }
        .requests-tab {
            td {
                padding-left: 10px;
            }
            .actions {
                & > div.action:first-child {
                    margin-left: 5px;
                    margin-right: 0;
                }
            }
        }
        .sharepbtns {
            float: left;
        }
        .modal-content {
            .sharepublinknew {
                & > label:first-child {
                    padding-bottom: 0;
                }
                .linkwrap input, .sharepbtns {
                    margin-top: 5px;
                }
                .sharepbtns {
                    .sharebutton.copylink, .sharebutton {
                        margin-right: 6px;
                    }
                }
                .getshortlink {
                    float: left;
                    margin-left: 90px;
                    margin-right: 0;
                }
                .shortlinkexplain {
                    text-align: right;
                }
            }
            .publinkbottom {
                .switch {
                    float: right;
                }
                .share {
                    float: left;
                }
            }
            .createshortlink {
                & > label:first-child {
                    padding-bottom: 0;
                }
            }
            .dataandshortlink {
                .data {
                    div + div {
                        text-align: right;
                    }
                }
            }
        }
        .deletelinks {
            float: left;
        }
        .search-filter {
            padding: 0 0 0 15px;
            img.icon {
                margin: -4px 0 0 10px;
            }
            img.drop {
                margin-left: 0;
                margin-right: 8px;
            }
        }
        .mn-holder.mnnew.jsmin.mn-filters.jsmn.fileopts {
            margin-left: -12px !important; 
        }
        .modal-files {
            text-decoration: none;
            .fprev {
            clear: both;
            margin-right: 15px;
                .folderlist {
                    text-decoration: none;
                }
                .folderlist.noselect {
                    text-decoration: none;
                }
                ul {
                    text-decoration: none;
                    li {
                        text-decoration: none;
                        .ctrl {
                            text-decoration: none;
                        }
                        .ftitle.foldr {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }

    .fa {
        .setup_box {
            .msg {
                padding: 20px 110px 20px 40px;
            }
            .legend {
                strong {
                    padding-left: 0;
                    padding-right: 20px;
                }
                ul {
                    padding: 0 36px 0 0;
                }
            }
            .pass_label {
                span.strngt {
                    float: left;
                    padding: 4px 0 0 23px;
                }
            }
            .pass_fill {
                left: 34px;
                right: auto;
                .pass_box {
                    left: 0;
                }
            }
            .ps2_err, .hint_err {
                text-align: left;
                padding: 6px 0 4px 23px;
            }
            .cryptopass2 {
                &.ok {
                    background-position: 10px center;
                }
            }
        }
        .unlock_box {
            .unlock_cry {
                height: 42px;
                padding: 5px 0 5px 0;
            }
            a.hint_btn {
                float: left;
            }
            .hint_box {
                background-position: 10px center;
            }
            .btn_box:first-child {
                padding-left: 5px;
                padding-right: 0;
            }
            .btn_box:last-child {
                padding-left: 0;
                padding-right: 5px;
            }
        }
        .buy_crypto .in_wrap {
            div {
                text-align: left;
                &:first-child {
                    text-align: right;
                }
            }       
        }
    }

    /*** Public links new ***/
    .fa.dlink {
        .header {
            .logo {
                margin-left: 0;
                border-left: none;
            }
            .profile {
                padding: 0;
                .settings {
                    margin-left: 12px;
                    margin-right: 3px;
                }
            }
            .buttons {
                float: left;
                .dropdown-button {
                    margin: 15px 0 0 15px;
                }
                a.share {
                    margin: 15px 5px 0 20px;
                }
            }
            .filename {
                span {
                    display: inline-block;
                    direction: ltr;
                    margin-right: 5px;
                }
            }
        }
        .mnnew.mn-share a, .mnnew.mn-share.leftdir ul a {
            padding-left: 25px;
            padding-right: 15px;
        }
        .mnnew.mn-share ul a li div.publink-sprite, .mnnew.mn-share ul a li img {
            margin-left: 10px;
            margin-right: 0px;
        }

        #dl_content .title .wrap {
            margin: 0 0 0 -50px;
        }
        #dl_content .title .wrap .filename {
            float: right;
        }
        #dl_content .title .wrap .size {
            float: left;
            min-width: 120px;
            margin-right: 10px;
            text-align: right;
        }
        .sa-player .bars .progress-bar, .sa-player .bars .volume {
            direction: ltr;
        }
    }

    .fa.dlink.mobile {
        .header {
            .logo .pcloud-logo {
                margin-left: 0;
                margin-right: 12px;
            }
            .buttons {
                a.share {
                    margin: 0;
                }
            }
        }
        #dl_content .title .wrap, #dl_content .title .wrap .size {
            margin: 0;
        }
    }

    /*** Public links ***/
    .fa .pub {
        direction: rtl;

        body {
            background-color: #333333;
        }
        .main {
            border: none;
            margin-right: 0;
            overflow-x: hidden;
            padding: 40px 10px 0px 10px;
        }
        #mobile-logo {
            float: right;
        }
        #mobile-logo a {
            padding: 12px 10px 8px 5px;
        }
        #fileinfo {
            float: right;
        }
        #buttons {
            float: left;
            margin-right: 0;
            direction: rtl;
            margin-left: 15px;
            a {
                margin-left: 5px;
                &.profile {
                    margin-left: 0;
                }
            }
        }
        .publink-sprite.save-to-pcloud {
            float: right;
            margin-left: 10px;
            margin-right: 0;
        }
        .tooltip-savetopcloud .icon {
            float: right;
            margin-left: 15px;
            margin-right: 0;
        }
        .close-tooltip {
            left: 10px;
            right: auto;
        }
        .tooltip-savetopcloud .tooltip-content {
            float: right;
            text-align: right;
        }
        .tooltip-savetopcloud .title, .tooltip-savetopcloud .description, .link {
            text-align: right;
        }
        .savetopcloud-container.desktop {
            left: 110px;
            right: auto;
        }
        div.cheader {
            div.fbreadcrumb {
                text-align: right;
            }
        }
        .mnnew.share a {
            padding-left: 25px;
            padding-right: 15px;
            .public-sprite.link {
                margin-left: 5px;
                margin-right: 0;
            }
        }
        .csortnew .darktip {
            right: -4px;
        }
        td.file, th.name {
            padding-right: 10px;
        }
    }
    .fa {
        .publinkmobile {
            .mobactions {
                float: left;
                margin: -5px -9px 0 0;
                background-position: 1px 12px;
            }
            div.iconwrap img.listicon {
                vertical-align: middle;
            }
            .mobgridheader {
                padding-left: 0;
                padding-right: 8px;
                height: 34px;
                line-height: 0px;
            }
            .mobgridheader img {
                margin: 3px 0 0 8px;
                vertical-align: top;
            }
            .mobgridheader b {
                padding: 15px 0 0 0;
                display: inline-block;
            }
            &.gridlist {
                margin-top: 16px !important;
            }
            #buttons {
                margin: 0;
            }
            div.afinfo {
                padding: 5px 10px 0 0;
                text-align: right !important;
            }
        }
        .action-sheet-item .title {
            float: right;
            margin-right: 16px;
            margin-left: 0;
        }
        .action-sheet-item .sheet-icon {
            float: left;
            margin-left: 16px;
            margin-right: 0;
        }
        img.listicon {
            margin: 0 -2px 0 2px;
        }
        .darkboxmob .dark-title {
            text-align: right;
            text-indent: 40px;
        }
        .darkboxmob .current {
            direction: ltr;
            left: initial;
            right: 0;
        }
        .darkboxmob .options {
            left: 0;
            right: initial;
            .more {
                padding-left: 20px;
                padding-right: 0;
                img {
                    float: left;
                }
            }
        }
        .darkboxmob .next {
            left: 0;
            right: initial;
            background-image: url('/ZCDNURL/img/mobile/prev@2x.png') !important;
        }
        .darkboxmob .prev {
            background-image: url('/ZCDNURL/img/mobile/next@2x.png') !important;
        }
        .pub.mobile {
            .main {
                clear: both;
                padding-left: 0;
                padding-right: 0;
            }
            #fileinfo {
                width: 100%;
                margin: 49px 0 -40px 0;
            }
            #buttons {
                margin-left: 0;
            }
        }
        .action-sheet-item.cancel .title {
            float: none;
            margin-left: 0;
            margin-right: 0;
        }
    }
}