.sharepublink-tabs.tabs {
	.ctrl {
	    height: 47px;
	    padding: 0 20px 0 20px;

		& > span {
		    border: none;
		    background: none;
		    text-align: center;
		    color: #000;
		    height: 46px;
		    line-height: 47px;
		    font-size: 13px;
		    min-width: 75px;

		    &:hover {
		    	background: none;
		    }

		    &.sel {
		    	line-height: 47px;
			    border: none;
			    border-bottom: 3px solid #1BCED8;
		    }
		}
	}

	.tabcontent {
		margin-top: 0;
	}
}

.topContain {
	display: none;
}

.share-publink {
	.share {
	    float: right;
	    margin: 10px 20px 10px 0;

		span {
		    line-height: 26px;
		    display: inline-block;
		    margin-right: 5px;

			a {
			    border: 1px solid #eaeaea;
			    border: none;
			    line-height: 28px;
			    height: 28px;
			    display: inline-block;
			    margin: 0 4px 0 0;
			    border-radius: 2px;
			}

			&:hover {
				opacity: 0.7;
			}

			&:last-child {
			    margin-right: 0;
			}
		}
	}

	.stats {
		.title-wrap {
			display: none;

			.total-traffic {

			}
		}

		.linkstats-wrap {
			background: #fafafa;
			border: 1px solid #e9e9e9;
			margin-bottom: 10px;
			min-width: 500px;
			position: relative;

			@media (max-width: 800px) {
				min-width: auto;
			}

			.wrap-loading {
				display: none;
				position: absolute;
				top: 0;
				width: 100%;
				left: 0;
				bottom: -15px;

				background: url("/ZCDNURL/img/loading.gif") no-repeat center center;
			}

			&.inloading {
				opacity: 0.5;

				.wrap-loading {
					display: block;
				}
			}

			.linkstats {
				width: 547px;
				/*border-bottom: 1px solid #666;*/
				margin: 30px auto 25px auto;
				padding: 0;
				position: relative;

				@media (max-width: 800px) {
					width: 100%;
				}

				.days {
					border-bottom: 1px solid #0e74da;
					/*padding: 0 40px 0 40px;*/
					padding: 0 20px 0 20px;
					margin: 0 auto;
					width: 364px;
					height: 160px;
					line-height: 160px;
					position: relative;


					@media (max-width: 800px) {
						width: 300px;
					}
					@media (max-width: 500px) {
						width: 200px;
					}
					@media (max-width: 400px) {
						width: 120px;
					}

					& > .pad {
						width: 1px;
						margin-left: -1px;
						height: 100px;
					}

					& > .bar {
						background: rgba(14, 116, 218, 1);
						border: none;
						border-bottom: none;
						width: 50px;
						display: inline-block;
						margin: 0 2px 0 0;
						vertical-align: bottom;
						cursor: default;
						opacity: 1;
						position: relative;
						z-index: 10;
						min-height: 1px;

						@media (max-width: 800px) {
							width: 12%;
						}

						&.empty {
							border-color: rgba(0, 0, 0, 0);
						}

						span {
							font-weight: bold;
							position: absolute;
							bottom: 0;
							font-size: 11px;
							line-height: 26px;
							margin-right: 1px;
							margin-bottom: -26px;
							height: 26px;
							text-align: center;
							width: 100%;

							@media (max-width: 800px) {
								left: 0;
							}
						}

						&.iperiod {
							opacity: 1;
						}

						&:hover {
							background: rgba(14, 116, 218, 0.9);
						}

						&:nth-child(8) {
							margin-right: 0;
						}
					}

					.avg {
						padding: 7px 0 7px 0;
						position: absolute;
						left: 0;
						right: 0;

						div {
							border-bottom: 1px dashed #000;
						}

						&:hover {
							border-color: #666;
						}
					}

					.underline {
						background: #0e74da;
						height: 1px
					}

					.line-legend {
						position: absolute;
						color: #cccccc;
						font-size: 11px;
						width: 92px;

						line-height: 14px;
						margin-bottom: -3px;

						&.left {
							left: -100px;
							text-align: right;
						}

						&.right {
							right: -100px;
							text-align: left;
						}

						&.top {
							top: 10%;
						}

						&.bottom {
							bottom: -3px;
						}

						&.middle {
							top: 55%;
						}
					}

					.line {
						background: #eee;
						height: 1px;
						position: absolute;
						left: 0;
						right: 0;
						z-index: 8;

						&.top {
							top: 10%;
						}

						&.middle {
							top: 55%;
						}
					}
				}

				.overview {

					.summary {
						padding: 10px 0 10px 0;

						margin-left: 143px;
						margin-right: 90px;

						text-align: center;
						font-size: 13px;
						line-height: 20px;
						margin-top: 0;

						.border {
							height: 100%;
							width: 1px;
							background: #fff;
							border-right: 1px dashed #333;
							position: absolute;
							top: 0;

							visibility: hidden;

							&.left {
								left: 83px;
							}

							&.right {
								right: 90px;
							}
						}

						.title {
							position: absolute;
							padding: 0 14px 0 14px;

							font-size: 13px;
							line-height: 28px;

							top: 45px;
							margin-top: -66px;

							left: 50%;
							margin-left: -178px;
							width: 328px;


							border: none;
							background: none;
						}

						.summary-wrap {
							position: absolute;
							bottom: 0;
							left: 0;
							right: 0;
							width: 100%;
							text-align: center;
							margin-bottom: -40px;

							.summary-text {
								padding: 0 5px 0 5px;
								display: inline-block;

								border: 1px solid #e9e9e9;
								background: #fff;
								font-size: 13px;
								line-height: 28px;
								height: 28px;
								white-space: nowrap;

								.prev, .next {
									vertical-align: top;
									cursor: pointer;
									display: inline-block;
									width: 20px;
									height: 28px;
									background-size: 6px 10px;
									background-position: center center;
									background-repeat: no-repeat;
									opacity: 1;
									margin: 0;
								}

								.prev:hover, .next:hover, .prev.inactive, .next.inactive {
									opacity: 0.5;
								}

								.prev {
									margin-right: 20px;
									@mixin retina '/ZCDNURL/img/dlink/back.png';
								}

								.next {
									margin-left: 20px;
									@mixin retina '/ZCDNURL/img/dlink/next.png';
								}
							}
						}

						b {
							font-size: 14px;
							display: inline;
							line-height: initial;
						}
					}
				}
			}
		}
	}
}

.dataandshortlink {
	.data {
		margin: 0;
		padding: 0 0 20px 0;
		border-bottom: none;

		.column {
			width: 33%;
			float: left;
			text-align: center;

			&:first-child {
				text-align: left;
			}

			&:last-child {
				text-align: right;
			}

			& > div {
				display: inline-block;
				line-height: 16px;
				font-size: 13px;
				min-width: 120px;

				& > * {
					float: left;
					text-align: left;
				}
			}
		}

		.name {
			color: #999;
			font-weight: normal;
		}

		.value {
			font-weight: bold;
			color: #000;
		}

		.data-icon {
			background-size: 32px 32px;
			width: 32px;
			height: 32px;
			margin: 0 10px 0 0;

			&.created {
				@mixin retina '/ZCDNURL/img/dlink/link-created.png';
			}

			&.downloads {
				@mixin retina '/ZCDNURL/img/dlink/link-downloaded.png';
			}

			&.traffic {
				@mixin retina '/ZCDNURL/img/dlink/link-traffic.png';
			}
		}
	}
}

.sharepublinknew {

	.title {
		span:first-child, span:last-child {
			cursor: pointer;
			font-weight: bold;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	&.single-link {
		.title {
			span {
				display: none;

				&:first-child {
					display: inline;
					text-decoration: none;
					cursor: default;
				}
			}
		}
	}

	&.shortlink .title span:last-child, &.longlink .title span:first-child {
		cursor: default;
		font-weight: normal;
		text-decoration: none;
	}

	&.shortlink {
		.linkwrap.shortlink {

		}

		.linkwrap.longlink {
			display: none;
		}
	}

	&.longlink, &.single-link {
		.linkwrap.shortlink {
			display: none;
		}

		.linkwrap.longlink {

		}
	}

	.linkwrap {
		input {
			background: #fafafa;

			&:focus {
				background: #fff;
			}
		}
	}

	.tomails {
		.combo-contain {
			background: #fafafa;

			&:focus {
				background: #fff;
			}
		}
	}

	textarea {
		background: #fafafa;

		&:focus {
			background: #fff;
		}
	}
}

.publink-edit {
	width: initial;
	background: #fff;
	padding: 25px 20px;
	width: 550px;

	.limits-wrap {
		padding: 0;
		margin: 0;
		border: none;

		tr {
			td {
				vertical-align: top;
				line-height: 34px;

				input.dt {
					width: 100px;
					background-color: #fafafa;
				}

				select {
					background-color: #fafafa;
				}

				&:first-child {
					width: 189px;
				}
			}

			&:first-child {
				td {
					padding-bottom: 20px;
					/*padding-bottom: 15px;*/
				}
			}

			&:last-child {
				td {
					padding-top: 20px;
					/*padding-bottom: 15px;*/
				}
			}
		}

		input[name="password"] {
			width: 148px;
			height: 30px;
			background: #fafafa;
		}

		.password {
			position: relative;

			.el-overlay {
				background: #fff;
			}

			&.active {
				.el-overlay {
					display: none;
				}
			}
		}

		.expiration {


			.el-overlay {
				background: #fff;
			}
		}

		.shortlink {
			position: relative;
			line-height: 17px;

			input {
				float: left;
				width: 148px;
				height: 30px;
				background-color: #fafafa;
			}

			.sharepbtns {
				float: left;

				.sharebutton {
					vertical-align: top;
					display: inline-block;
					width: 34px;
					height: 34px;

					&.copylink {
						background: #1bc9d3 url('/ZCDNURL/img/ul/copy-link.png') no-repeat center center;

						&:hover {
							background: #18B5BD url('/ZCDNURL/img/ul/copy-link.png') no-repeat center center;
						}
					}

					&.openlink {
						margin: 0;
						background: #a4a4a4 url('/ZCDNURL/img/ul/open-new-window.png') no-repeat center center;

						&:hover {
							background: #939393 url('/ZCDNURL/img/ul/open-new-window.png') no-repeat center center;
						}
					}

					img {
						display: none;
						width: 34px;
						height: 34px;
					}
				}
			}

			/*
			& > * {
				display: none;
			}
			*/

			.el-overlay {
				background: #fff;
			}

			&.active {
				.el-overlay {
					display: none;
				}
			}

			&.has-shortlink > * {
				/*display: block;*/

				.el-overlay {
					display: none;
				}
			}
		}
	}

	.inline-butt-area {
		margin-top: 17px;
	}
}

.linksettings {
	padding: 0;
	margin: 0 !important;
	width: inherit;

	.shortlinkpart {
		display: none;
	}

	.linksettings-footer {
		border-top: 1px solid #E9E9E9;
		line-height: 48px;
		padding: 0 20px 0 20px;

		.delete {
			cursor: pointer;
			color: red;
			font-weight: bold;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}
}

a.upgrade {
	/*display: none !important;*/
}

/*.ribbon {
	display: none;
	cursor: pointer;
	margin: 0 20px 0 0;
	float: right;
	width: 52px;
	height: 73px;
	background-repeat: no-repeat;
	background-size: 52px 73px;
	@mixin retina '/ZCDNURL/img/ribbon.png';
}

.newribbon {
	display: none;
	cursor: pointer;
	margin: 0 20px 0 0;
	float: right;
	width: 52px;
	height: 73px;
	background-repeat: no-repeat;
	background-size: 52px 73px;
	@mixin retina '/ZCDNURL/img/yearpromo/flag-on-header.png';
}*/


/* upgrade to yearly promotion */


.yearly-upgrade {
	position: relative;

	.close {
		position: absolute;
		top: -20px;
		right: -20px;

		cursor: pointer;
		width: 45px;
		height: 45px;
		border-radius: 30px;
		background: #fff url('/ZCDNURL/img/yearpromo/close.png') no-repeat center center;
	}
}

/*
.newribbon.animate {
 -webkit-animation-duration: 1s;
 -moz-animation-duration: 1s;
 -o-animation-duration: 1s;
 animation-duration: 1s;
 -webkit-animation-fill-mode: both;
 -moz-animation-fill-mode: both;
 -o-animation-fill-mode: both;
 animation-fill-mode: both;

 animation-name: flagWave;
}

@keyframes flagWave {
 0% {
   transform-origin: 0 0;
   transform: perspective(800px) rotateX(90deg);
 }

 50% {
   transform-origin: 0 0;
   transform: perspective(800px) rotateX(-30deg);
 }
 100% {
   transform-origin: 0 0;
   transform: perspective(800px) rotateX(0deg);
 }
}*/


.time-heading, .time-heading-inner {
	.time_settings {
		margin: var(--spacing-xs) 0 var(--spacing-md);
		float: none;
		width: 100vw;
		max-width: 500px;
		min-width: auto;
		box-sizing: border-box;
		padding: 0 var(--spacing-sm);

		@media (max-width: 1023px) {
			padding: 0 var(--spacing-md);
		}
		.filter_dt {
			position: relative;

			input {
				box-sizing: border-box;
				margin: 0;
				width: 100%;
				height: 40px;
				padding: var(--spacing-none) var(--spacing-xs) var(--spacing-none) var(--spacing-md);
				color: var(--text-primary);
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				border-radius: 88px;
				border: 1px solid var(--border-primary);
				background-color: var(--surface-secondary);
				box-shadow: none;

				&:focus{
					outline:none;
					border: 1px solid var(--color-primary-500);
				}

				&::-moz-placeholder,
				&::-ms-input-placeholder,
				&::placeholder {
					color: var(--text-secondary);
					font-size: 14px;
					font-weight: 400;
					line-height: 20px;
				}
			}

			&:after {
				font-family: 'Font Awesome 6 Pro';
				font-weight: 400;
				font-size: 16px;
				content: '\f073';
				color: #7A8D8F;
				position: absolute;
				right: 16px;
				top: 11px;
				pointer-events: none;
			}
		}
	}
}
.timemachine,
.timepage {
	height: calc(100% - var(--spacing-md) - 16px)!important;
}
.filemanager.with-time {
	height: calc(100% - var(--spacing-md) - 42px)!important;
}

.timemachine {
	.start {
		font-size: 15px;
		position: absolute;
		padding: 8px 10px 10px 10px;
		border-radius: 5px;
		background-color: #17bed0;
		color: #FFF;
		cursor: pointer;
		left: 50%;
		bottom: 10px;
		top: auto;
		margin: 0 0 0 -54px;
	}
	.start.disabled {
		cursor: default;
		background-color: #e8e8e8;
	}

	/*** Animation CSS ***/

	.wrap_anim {
		display: table;
		margin: 0 auto;
		position: relative;
		@media (max-width: 800px) {
			display: none;
		}
	}
	.clock_bg {
		top: 36px;
		left: 50%;
		margin: 0 0 0 -50px;
		position: absolute;
	}
	.clock_arrow {
		position: absolute;
		top: 51px;
		left: 50%;
		margin: 0 0 0 -40px;
	}
	.progress_wrap {
		position: absolute;
		top: auto;
		bottom: 40px;
		right: 42px;
		width: 655px;
		height: 13px;
	}
	.folders_ {
		-moz-opacity: 0;
		-khtml-opacity: 0;
		-webkit-opacity: 0;
		opacity: 0;
		position: absolute;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		-webkit-transition: opacity .6s ease;
		-moz-transition: opacity .6s ease;
		-o-transition: opacity .6s ease;
		transition: opacity .6s ease;
	}

	.folder_1 {
		bottom: 73px;
		right: 212px;
		bottom: 69px;
	}
	.folder_2 {
		bottom: 73px;
		right: 424px;
		bottom: 69px;
	}
	.folder_3 {
		bottom: 73px;
		left: 0;
		bottom: 69px;
	}
	.show_folder {
		-moz-opacity: 1;
		-khtml-opacity: 1;
		-webkit-opacity: 1;
		opacity: 1;
	}

	.wrap_anim.animate .clock_arrow {
		-webkit-animation: counterspin 1.5s linear infinite;
		-moz-animation: counterspin 1.5s linear infinite;
		animation: counterspin 1.5s linear infinite;
	}

	.wrap_anim.animate .progress_bar {
		width: 13px;
		height: 13px;
		right: 0;
		position: absolute;
		background-color: #17bed0;

		-webkit-border-radius: 100px;
		-moz-border-radius: 100px;
		border-radius: 100px;
		-webkit-box-shadow: 0px 1px 3px rgba(0,0,0,0.15);
		-moz-box-shadow: 0px 1px 3px rgba(0,0,0,0.15);
		box-shadow: 0px 1px 3px rgba(0,0,0,0.15);

		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		-webkit-transition: width 2s linear;
		-moz-transition: width 2s linear;
		-o-transition: width 2s linear;
		transition: width 2s linear;
	}
}

.crypto-container {
	height: 100%;
	overflow-y: auto;
}

@-moz-keyframes counterspin { 100% { -moz-transform: rotate(-360deg); } }
@-webkit-keyframes counterspin { 100% { -webkit-transform: rotate(-360deg); } }
@keyframes counterspin { 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); } }

.time_upgrade_and {
	text-align: left;
	margin-top: 10px;

	b {
		margin-bottom: 0 !important;
		padding-bottom: 0;
	}

	ul {
		margin: 0px;
		padding: 5px 0 0 25px;
	}
}

.img {
	height: 100%;

	.player-holder {
		.video-error {
			height: 360px;
			font-size: 15px;

			img {
				position: static;
				margin: 30px auto 60px auto;
			}
		}
	}

	.img-error {
		display: flex;
		min-height: 100%;
		justify-content: center;
		align-items: center;
		font-size: 15px;
		color: #fff;

		img {
			position: static;
			margin: 30px auto 60px auto;
		}
	}
}

.yellow-line {
    display: none;
    background: #fff5d7;
		border-bottom: 1px solid #e4e4e4;
    height: 40px;
    line-height: 40px;
    color: #68645d;
    width: 100%;

		.yellow-wrap {
			margin: 0 auto;
			max-width: 1180px;
			position: relative;

			.yellow-text {
				text-align: center;
				font-size: 13px;

				a {
					color: #1bc7cf;
					text-decoration: underline;
					font-weight: 600;
				}
			}

			.yellow-close {
				position: absolute;
				right: -20px;
				top: 0;
				@mixin retina '/ZCDNURL/img/mobile/ads-2016/yellow-close.png';
				background-repeat: no-repeat;
				width: 52px;
				height: 40px;
				background-size: 12px 12px;
				background-position: center center;
				cursor: pointer;

				&:hover {
					opacity: 0.7;
				}
			}
		}

	&.fulltime {
		padding-right: 8px !important;

		.yellow-text {
			text-align: center !important;
		}

		.yellow-close {
			display: none;
		}
	}
}

.yellow-on {
		.header-wrap {
			/*height: 94px;*/
			height: initial;

			.header {

			}
		}

    .yellow-line {
      display: block;
    }

		.content {
			padding-top: 101px;
		}
}

.mob, .mobile {
	.header-wrap {
		// position: static;
		box-shadow: none;
		height: initial;

		.yellow-text {
			text-align: left;
		}

		.yellow-line {
			line-height: 19px;
			height: initial;
			padding: 5px 30px 5px 8px;
			box-sizing: border-box;

			.yellow-close {
				top: -5px;
				right: -35px;
			}
		}
	}

	.cnt-mn {
		padding-top: 45px;

		.filemanager {
			min-height: 50vh;
		}
	}
}

.row-more {
	display: none;
	/*visibility: hidden;*/
	cursor: pointer;
	vertical-align: middle;
	width: 30px;
	height: 18px;
	background-size: 18px 4px;
	background-position: center center;
	background-repeat: no-repeat;
  opacity: 0.6 !important;
	@mixin retina "/ZCDNURL/img/header/more-active.png";

	&:hover, &.opn {
  	opacity: 1!important;
	}
}

td .row-more {
	margin-left: 5px;
}

td:hover .row-more,
tr.lastsel td .row-more,
.row-more.opn {
  display: inline-block;
  visibility: visible;
}

div.gridbox:hover .row-more {
	display: inline-block;
	visibility: visible;
}

div.gridbox:hover .row-more.selection-mode {
  display: none;
  visibility: hidden;
}

// div.gridbox.sel:first-child:nth-last-child(1) {
// 	display: inline-block;
// 	visibility: visible;
// }

.fbreadcrumb .row-more {
    display: inline-block;
    position: relative;
    margin: 0;
    visibility: visible;
    zoom: 1;
    opacity: 1;
    cursor: pointer;
    margin: -1px 0 1px 7px;
    vertical-align: middle;
}

.header-container.europe {
  .header .header-links a {
    color: #fff;
  }

  .header .logo-place .pcloug-logo-lone {
    background-size: 133px 32px;
    // @mixin retina "/ZCDNURL/img/logo_white.png";
  }
}

.header {
	.logo-place {
		line-height: 60px;
		font-size: 23px;
		color: #2B2B2C;
		letter-spacing: -2px;
		float: left;

		a {
			color: #000;
			text-decoration: none;
			display: block;
		}

		.pcloug-logo-lone {
			height: 60px;
			margin-right: 11px;
			display: flex;
			align-items: center;
			position: relative;
		}

		.mobile.pcloug-logo-lone {
			margin-top: 0;
			margin-left: 11px;
		}
	}

	.header-links {
		height: 60px;
		margin-right: 0;
		display: flex;
		align-items: center;

		a {
			color: #2a2a2b;
			text-decoration: none;
			margin: 0 0 0 28px;
			font-size: 15px;
			font-weight: 700;

			&.download_button {
				border: solid 1px #bcecf1;
				background-color: #f4fcfd;
				color: #17bed0;
				box-sizing: border-box;
			}

			&.share_button {
				border: solid 1px #bcecf1;
				background-color: #f4fcfd;
				color: #17bed0;
				box-sizing: border-box;
			}

			&:first-child {
				margin: 0;
			}
		}

		.services-button {
			display: inline-block;
			height: 17px;
			margin: 0 0 0 37px;
		}
	}

	.header-links.left-alignment {
		float: left;
		margin-left: 13px;
	}

	.header-links.right-alignment {
		float: right;
	}

	.user-holder {
		float: right;
    height: 60px;
    // width: 147px;

		.signin {
			text-decoration: none;
	    color: #17bed0;
	    font-size: 14px;
	    font-weight: 700;
	    margin: 22px 0 0 37px;
	    padding: 0 0 0 33px;
	    border-left: 1px solid #ccc;
		}
	}

	.signin {
		visibility: hidden;
	}

	.notifications, .profile {
		display: none;
	}

	&.logged {
		.notifications, .profile {
			display: block;
			text-align: center;
		}

		.signin {
			display: none;
		}
	}

	&.notlogged {
		.signin {
			visibility: visible;
			display: block;
		}
	}
}

.grid-search {
	width: 270px;
	margin: 0 0 0 0;
	position: relative;
	z-index: 1;

	// input {
	// 	height: 32px;
	// 	box-sizing: border-box;
	// 	width: 260px;
	// 	padding: 0 0 0 5px;
	// 	float: right;
	// 	border: 1px solid #cdcdcd;
	// 	font-size: 13px;
	// 	padding-right: 75px;
	// }

	.search-butt {
		background: #f8f8f8;
		width: 56px;
		height: 30px;
		line-height: 30px;
		border: 1px solid #cdcdcd;
		border-left: none;
		float: right;
		cursor: pointer;

		background-repeat: no-repeat;
		background-position: center center;
		background-size: 14px 14px;
		@mixin retina "/ZCDNURL/img/grid/search.png";

	}

	.search-filter {
		position: absolute;
		top: 0;
		right: 56px;
	}
}

.createfolder {
	cursor: pointer;
	height: 26px;
	width: 19px;
	margin: 10px 15px 0 15px;

	float: left;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 19px 19px;
	@mixin retina "/ZCDNURL/img/grid/new-folder.png";

	&:hover {
		@mixin retina "/ZCDNURL/img/grid/new-folder-active.png";
	}
}

.uploadfiles {
	cursor: pointer;
	float: left;

	margin-top: 9px;
	height: 28px;
	line-height: 28px;
	background: #17bed0;
	color: #fff;
	font-size: 13px;
	text-align: center;
	padding: 0 20px 0 20px;
	border-radius: 3px;
	margin-right: 15px;
	min-width: 108px;
  box-sizing: border-box;

	&:hover {
		background: #18bcc5;
	}

	/*
	margin-top: 10px;
	width: 40px;
	height: 26px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 14px 19px;
	@mixin retina "/ZCDNURL/img/grid/upload.png";

	&:hover {
		@mixin retina "/ZCDNURL/img/grid/upload-active.png";
	}
	*/
}

.upload-button {
	float: left;
}

.templateswitch {
	float: left;
	width: 17px;
	height: 26px;
	margin: 10px 15px 0 15px;

	div {
		width: 19px;
		float: left;
		width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
		background-size: 17px 19px;
		cursor: pointer;

		&.folderlist {
			@mixin retina "/ZCDNURL/img/grid/tumb-view-active.png";
		}

		&.foldergrid {
			@mixin retina "/ZCDNURL/img/grid/tumb-view.png";
		}
	}

	&.foldergrid, &.publicfoldergrid {
		.foldergrid {
			display: none;
		}
	}

	&.folderlist, &.publicfolderlist {
		.folderlist {
			display: none;
		}
	}
}

.arrow-bottom {
	display: inline-block;
  width: 0;
  height: 0;
  border-color: transparent;
  border: 5px solid transparent;
  border-top-color: #000;
}

.headerunder {
	.shadow-wrap {
		display: none;
		position: absolute;
		width: 100%;
		height: 10px;
		top: 0;
		left: 0;
		overflow: hidden;
		background: transparent;

		.shadow {
			position: absolute;
			width: 110%;
			height: 5px;
			top: -5px;
			left: -5%;
			box-shadow: 3px 1px 5px rgba(0,0,0,.09);
		}
	}

	&.flyinf {

	}
}

 .headerunder.team-header {
	 width: 100%;
 }

 .headerunder.users-header {
	width: 100%;
	height: auto;
}
.flying .shadow-wrap {
	display: block;
}

.upload {
	.minimize, .maximize {
		cursor: pointer;
		position: absolute;
		right: 0px;
		margin-top: 0;

		width: 35px;
		height: 34px;

		/*background: url(/ZCDNURL/img/minimize-visible.png) no-repeat left top;*/
		/*background: url('/img/header/min-max-modal.png') no-repeat left center;*/

		background-size: 10px 2px;
		@mixin retina "/ZCDNURL/img/header/minimaze-modal.png";
		background-repeat: no-repeat;
		background-position: center center;
		opacity: 0.35;

		&:hover {
			opacity: 1;
		}
	}


}

.upload_minimized {

	.maximize {
		cursor: pointer;
		position: absolute;
		right: 25px;
		margin-top: 0;

		width: 25px;
		height: 34px;

		/*background: url(/ZCDNURL/img/minimize-visible.png) no-repeat left top;*/
		/*background: url('/img/header/min-max-modal.png') no-repeat left center;*/

		background-size: 10px 2px;
		@mixin retina "/ZCDNURL/img/header/minimaze-modal.png";
		background-repeat: no-repeat;
		background-position: center center;
		opacity: 0.35;

		&:hover {
			opacity: 1;
		}
	}

	.close {
		cursor: pointer;
		position: absolute;
		right: 0px;
		margin-top: 0;

		width: 25px;
		height: 34px;

		/*background: url(/ZCDNURL/img/minimize-visible.png) no-repeat left top;*/
		/*background: url('/img/header/min-max-modal.png') no-repeat left center;*/

		background-size: 11px 11px;
		@mixin retina "/ZCDNURL/img/header/close-modal.png";
		background-repeat: no-repeat;
		background-position: center center;
		opacity: 0.35;
	}
}

.feature-modal {
	width: 640px;
	box-sizing: border-box;
	padding-top: 20px;
	border-radius: 10px;

	.g-modal-close {
		background: url("/ZCDNURL/img/close-to.png") no-repeat center center;
		background-size: 16px 16px;

		width: 50px;
		height: 50px;
	}

	.g-modal-content {
		border-radius: 10px;


		.feature-save-extension {
			width: 640px;
			height: 220px;
			background-size: 640px 220px;
			background-repeat: no-repeat;
			background-position: center center;
			@mixin retina "/ZCDNURL/img/save-to.png";
		}

		.feature-explain {
			font-size: 21px;
			color: #222;
			padding: 30px;
			text-align: center;

			.feature-button {
				font-size: 17px;
				color: #fff;
				margin-top: 30px;
				width: 240px !important;
			}
		}
	}
}

.modal-body-wrap {
	padding: 20px;

	input {
		width: 100%;
		box-sizing: border-box;
	}
}

.mob {
	.modal-body-wrap {
		padding: 20px 10px 20px 10px;


	}
}



.feature-summerpromo {
	width: 680px;
	box-sizing: border-box;
	border-radius: 20px;

	.g-modal-close {
		background: url("/ZCDNURL/img/close-to.png") no-repeat center center;
		background-size: 16px 16px;
		opacity: 1;
		width: 50px;
		height: 50px;

		&:hover {
			opacity: 0.7;
		}
	}

	.g-modal-content {
		border-radius: 20px;

		.lifetime-promo {
			background-color: rgb(255,255,255);
			padding: 35px 60px;
			box-sizing: border-box;
			border-radius: 20px;
			width: 680px;
			box-shadow: 0px 4px 8px rgba(0,0,0,0.15);
			text-align: center;

			.lifetime-promo-image, .efh-promo-image {
				background-image: url("/ZCDNURL/img/lifetime_promo_modal.png");
				width: 656px;
				height: 256px;
				background-size: 680px 256px;
				background-position: center center;
				background-repeat: no-repeat;
				margin-left: -50px;
			}

			.efh-promo-image {
				margin: 0 auto;
				width: 335px;
				height: 203px;
				background-size: 335px 203px;
				@mixin retina "/ZCDNURL/img/img-efh-feature.png";
			}

			/*.chinese-promo-image {
				margin: 0 auto;
				width: 347px;
				height: 56px;
				background-size: 347px 56px;
				@mixin retina "/ZCDNURL/img/11.11/11.png";
			}*/
			.chinese-promo-image {
				margin: 26px auto 42px;
				width: 275px;
				height: 167px;
				background-size: 275px 167px;
				@mixin retina "/ZCDNURL/img/singles-day/singles-day-30-off.png";
			}

			.chinese-promo-image-header {
				margin: 0 auto;
				width: 347px;
				height: 56px;
				background-size: 347px 56px;
				@mixin retina "/ZCDNURL/img/singles-day/happy.png";
			}

			.black-promo-image {
				margin: 0 auto;
				width: 184px;
				height: 83px;
				background-size: 184px 83px;
				@mixin retina "/ZCDNURL/img/50/50.png";
			}

			.h1 {
		    font-size: 23px;
				line-height: 30px;
		    font-weight: 500;
		    margin: 30px 0 0 0;
				letter-spacing: -1px;
		  }

		  .h2 {
		    font-size: 19px;
				letter-spacing: -1px;
		    font-weight: normal;
		    margin: 5px 0 30px 0;
		  }

			.button {
				width: 260px;
				background: #61c100;
				border-radius: 5px;
				box-sizing: border-box;
				text-transform: none;
				font-size: 15px;
				height: 44px;
				line-height: 44px;
				margin: 0 auto;
				margin-bottom: 0px;
			}

			.additional {
				font-size: 13px;
		    margin: 20px 0 0;
				color: #fff;
			}

			&.chinese-promo {
				background: #f54848;
				padding: 37px 60px 46px 60px;
				border-radius: 15px;

				.h1 {
					margin-top: 45px;
					font-size: 29px;
					font-weight: bold;
					color: #fff;
				}

				.h2 {
					color: #fff;
					font-size: 21px;
					margin-bottom: 40px;
				}

				.button {
					font-size: 17px;
					font-weight: bold;
					color: #F53F3F !important;
					background: #ffce3c;
					border-radius: 22px;
				}
			}

			&.black-promo {
				background: url("/ZCDNURL/img/50/bg.png") repeat;
				padding: 40px 60px;

				.h1 {
					color: #e64432;
			    font-weight: 700;
			    font-size: 25px;
			    text-transform: uppercase;
				}

				.h2 {
					text-transform: uppercase;
			    font-size: 17px;
			    font-weight: 600;
			    letter-spacing: -1px;
					color: #fcac18;
					margin-top: 0px;
					margin-bottom: 40px;
				}

				.button {
					background: #e64432;
					height: 43px;
					line-height: 43px;
					border-radius: 20px;
			    min-width: 230px;
			    text-transform: none;
					font-weight: bold;
				}

				.additional {
					color: #fcac18;
					margin-top: 40px;
					margin-bottom: 0;
				}
			}

			&.christmas-promo {
				background: url("/ZCDNURL/img/christmas/bg.jpg") repeat;
				padding: 25px;
				font-weight: bold;

				&.bundleLIFETIME {
					.for-bundle {
						display: none;
					}
				}

				&.bundleEFH {
					.for-lifetime {
						display: none;
					}
				}

				.over-title {
					color: #17bed0;
					font-size: 16px;
				}

				.h1 {
					background: #ff5555;
					height: 48px;
					line-height: 50px;
					color: #fff;
					font-weight: bold;
					font-size: 19px;
					letter-spacing: 2px;
					margin: 25px 60px;
					position: relative;
					border-bottom: 4px solid #e6ebed;

					b {
						display: inline-block;
						width: 5px;
				    height: 5px;
				    border-radius: 100%;
				    background: #fff;
				    vertical-align: middle;
						margin: -4px 10px 0 10px;
					}

					.left-rib {
						position: absolute;
						left: -50px;
						top: 0;
						width: 70px;
						height: 63px;
						background-size: 70px 63px;
						@mixin retina "/ZCDNURL/img/christmas/left-corner.png";
					}

					.right-rib {
						position: absolute;
						right: -50px;
						top: 0;
						width: 70px;
						height: 63px;
						background-size: 70px 63px;
						@mixin retina "/ZCDNURL/img/christmas/right-corner.png";
					}
				}

				.product {
					color: #17bed0;
					font-size: 26px;
					line-height: 26px;
					letter-spacing: -1px;
				}
				.plus {
					color: #f55;
					font-size: 24px;
					line-height: 20px;
					margin-top: -3px;
			    vertical-align: top;
				}
				.button {
					margin: 40px auto 0 auto;
					background: #f55;
					font-weight: bold;
					font-size: 14px;
					height: 50px;
			    line-height: 49px;
			    border-radius: 26px;
				}
				.additional {
					color: #cccece;
				}
			}
		}

		.summer-promo {
			background-color: rgb(255,255,255);
			width: 680px;
			height: 460px;
			padding: 20px;
			box-sizing: border-box;
			border-radius: 20px;
			box-shadow: 0px 4px 8px rgba(0,0,0,0.15);
			background-image: url("/ZCDNURL/img/summer-promo/ml_bg.png");

			.left-white {
				background-color:rgba(255,255,255,0.9);
				width:320px;
				height:420px;
				-moz-border-radius:8px;
				-webkit-border-radius:8px;
				border-radius:8px;
				-moz-box-shadow:0px 0px 1px rgba(0,0,0,0.06);
				-webkit-box-shadow:0px 0px 1px rgba(0,0,0,0.06);
				box-shadow:0px 0px 1px rgba(0,0,0,0.06);
				text-align: center;
				box-sizing: border-box;
				padding: 40px 20px 0 20px;

				h2 {
					font-size: 21px;
					line-height: 25px;
					font-weight: bold;
					color: #8BC543;
					margin-bottom: 38px;
				}

				span {
					 font-size: 17px;
					 color: #333;

					 &.instead {
						 color: #999;
						 line-height: 66px;
					 }
				}

				.green-line {
					background: #8BC543;
					height: 1px;
					width: 50px;
					margin: 27px auto;
				}

				.price {
					font-size: 31px;
					font-weight: bold;
				}

				.limited {
					font-size: 12px;
					line-height: 50px;
					color: #999;
				}

				.button {
					width: 260px;
					background: #8BC543;
					border-radius: 20px;
					box-sizing: border-box;
					text-transform: none;
					font-weight: bold;
					height: 44px;
					line-height: 44px;
					margin: 0 auto;
				}
			}
		}
	}
}

.chinese-promo {
	.g-modal-close {
		background: url("/ZCDNURL/img/singles-day/close.png") no-repeat center center;
		background-size: 32px 32px;
		opacity: 1;
		width: 60px;
		height: 60px;

		&:hover {
			opacity: 0.7;
		}
	}
}

.black-friday-promo {
	.black-wrap {
		border-radius: 15px;
		padding: 15px;
    box-sizing: border-box;
		background: #1a1a1a;

		.black-promo {
			width: 100%;
			border: 1px solid #622923;
			border-radius: 8px;
		}
	}

	.g-modal-close {
		background: url("/ZCDNURL/img/50/close.png") no-repeat center center;
		background-size: 30px 30px;
		opacity: 1;
		width: 60px;
		height: 60px;
		top: 10px;
		right: 10px;

		&:hover {
			opacity: 0.7;
		}
	}
}

.christmas-modal-promo {
	.g-modal-close {
		width: 30px;
		height: 30px;
		background-size: 30px 30px;
		@mixin retina "/ZCDNURL/img/christmas/close.png";
		top: 15px;
		right: 15px;
	}
}

.mob {
	.feature-summerpromo {
		width: 90%;

		.g-modal-inner {

			.g-modal-content {

				.lifetime-promo {
					width: 100%;
					padding: 50px 20px 40px 20px;
					border-radius: 7px;

					.chinese-promo-image {

					}

					.h1 {

					}

					.h2 {

					}
				}

				.christmas-promo {
					padding-top: 20px;
					padding-bottom: 20px;

					background: url("/ZCDNURL/img/christmas/bg-mobile-cut.jpg") center 65px no-repeat #fff;

					.over-title {
						font-size: 12px;
				    color: #2c2c2c;
				    margin: 0px 0 15px;
					}

					.h1 {
						height: 37px;
						line-height: 37px;
						font-size: 16px;
						margin-left: 15px;
						margin-right: 15px;
						margin-top: 15px;

						b {
							display: none;
						}

						.left-rib {
							position: absolute;
							left: -50px;
							top: 0;
							width: 52px;
							height: 48px;
							background-size: 52px 48px;
							@mixin retina "/ZCDNURL/img/christmas/left-corner-mobile.png";
						}

						.right-rib {
							position: absolute;
							right: -50px;
							top: 0;
							width: 52px;
							height: 48px;
							background-size: 52px 48px;
							@mixin retina "/ZCDNURL/img/christmas/right-corner-mobile.png";
						}
					}
				}
			}
		}

		.g-modal-close {
			width: 50px;
			height: 50px;
		}

		&.christmas-modal-promo {
			.g-modal-close {
				top: -23px;
				right: -23px;
				opacity: 1;
				@mixin retina "/ZCDNURL/img/christmas/close-mobile.png";
			}
		}
	}
}

.header {
	.summer-promo-wrapper {
		float: right;
		width: 75px;
		height: 60px;

		&.chinese-wrapper {
			margin-right: 40px;
		}
	}
	.summer-promo {
		display: none;
		float: right;
		margin-right: 20px;
		width: 54px;
		height: 74px;
		background-size: 54px 74px;
		background-position: center center;
		background-repeat: no-repeat;
		@mixin retina "/ZCDNURL/img/summer-promo/ribbon.png";

		&.chinese-promo {
			width: 78px;
			height: 60px;
			background-size: 78px 61px;
			@mixin retina "/ZCDNURL/img/singles-day/ribbon-singles-day.png";
			margin-bottom: -22px;
		}

		&.black-promo {
			width: 76px;
			height: 81px;
			background-size: 76px 81px;
			@mixin retina "/ZCDNURL/img/50/rib.png";
			margin-bottom: -22px;
		}

		&.christmas-promo {
			width: 68px;
			height: 83px;
			background-size: 68px 83px;
			@mixin retina "/ZCDNURL/img/christmas/ribbon.png";
			margin-bottom: -22px;

			&.bundleLIFETIME {
				@mixin retina "/ZCDNURL/img/christmas/ribbon-super.png";
			}
		}
	}
}

.ribbon-wrapper {
	position: relative;
	z-index: 1;
	margin-right: 0;
  height: 60px;
  
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: flagWave;
  -moz-animation-name: flagWave;
  -o-animation-name: flagWave;
  animation-name: flagWave;

	img {
		background-position: center center;
		background-repeat: no-repeat;
	}
}
html.dlink .ribbon-wrapper {
	margin-right: 20px;
	float: right;
}

.free-user .summer-promo {
	display: block;
}

.page-login {
	.main {
		min-height: initial;
	}

	.header {
		display: none;
	}

	.mobile.new-login {
		position: inherit !important;
	}

	.mobfooter {
		display: none;
	}
	.header, .content {
		max-width: initial;
		padding: 0px !important;
	}
}

div.iconwrap div.ispublic {
	@mixin retina "/ZCDNURL/img/ispublic.png";
	background-size: 13px 13px;
	background-position: center center;
	background-repeat: no-repeat;

  background-color: #FFF;
  width: 13px;
  height: 13px;

  border: 2px solid rgb(255, 255, 255);

  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

.left-menu-item-downloadlink.limited {
	@mixin retina "/ZCDNURL/img/publicfolder/warning.png";
	background-position: right center;
	background-repeat: no-repeat;
	background-size: 14px 14px;
}

li.open .left-menu-item-downloadlink.limited {
	background: none;
}

.hidden-under {
	position: fixed;
	bottom: 50px;
	left: 20px;
	right: 20px;
	text-align: center;
	transform: translateX(0%) translateY(300px);
	transition: 0.5s ease-in-out;
	z-index: 1000;

	&.appear {
		transform: translateX(0%) translateY(0px);
	}
}

.fulltextwrap {
	box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.45), 4px 4px 10px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	background: #fff;

	ul {
		float: right;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		box-shadow: none;
		border-left: 1px solid #e0e0e0;
	}

	.fulltextsearch {
		float: left;
		height: 100%;
		width: 195px;
		background: #fff;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
    margin-right: -1px;

		.fulltextsearch-getpremium {
			padding: 20px 15px 15px 15px;
			text-align: center;
			border-right: 1px solid #e0e0e0;

			.ftsimage {
				width: 56px;
				height: 68px;
				background-size: 56px 68px;
				background-repeat: no-repeat;
				background-position: center center;
				margin: 0 auto 15px auto;
				@mixin retina "/ZCDNURL/img/fts.png";
			}

			b {
				font-size: 14px;
				font-weight: bold;
			}

			.more-info {
				font-size: 13px;
			}

			.learn-more {
				font-size: 11px;
				color: #17bed0;
				line-height: 32px;
				height: 35px;
				padding: 0;
				display: inline-block;

				&:hover {
					text-decoration: none;
				}
			}

			.button {
				width: 100%;
				box-sizing: border-box;
				height: 32px;
				line-height: 32px;
				font-size: 13px;
			}
		}
	}
}

.index-status {
  background-size: 18px 18px;
	background-repeat: no-repeat;
	background-position: center center;
  width: 18px;
  height: 18px;
  margin: -4px 0 0 8px;
	vertical-align: middle;
  margin-top: -5;
  display: inline-block;
  cursor: default;

	&.ready {
		@mixin retina "/ZCDNURL/img/fts/all-ok.png";
	}

	&.not-ready {
		@mixin retina "/ZCDNURL/img/fts/all-warning.png";
	}
}

.index-status + .tooltip {
	.tooltip-inner {
    max-width: initial;
	}
}

.notification-wrap {
	.notification-icon {

	}
}

.doc-password {
	@mixin retina "/ZCDNURL/img/pass-protected.png";
	width: 140px;
	height: 140px;
	background-size: 140px 140px;
	margin: 10px auto 30px auto;
}

.has-password .is-unreadable, .is-password {
	display: none;
}

.has-password .is-password {
	display: block;
}

@keyframes flagWave {
  0% {
    -webkit-transform-origin: 0 0;
    -webkit-transform: perspective(800px) rotateX(90deg);
  }
  50% {
    -webkit-transform-origin: 0 0;
    -webkit-transform: perspective(800px) rotateX(-30deg);
  }
  100% {
    -webkit-transform-origin: 0 0;
    -webkit-transform: perspective(800px) rotateX(0deg);
  }
}

@media (min-width: 1024px) {
	html:not(.dlink) #fixedBottomArea {
		position: fixed;
		bottom: 3px;
		right: 15px;
		z-index: 103;
		display: flex;
		flex-direction: column;
		align-items: end;
		pointer-events: none;

		& > * {
			pointer-events: auto;

			&:not(#audioplayer-container):not(#content-sticky-place):not(#upload-manager-container) {
				margin-bottom: 5px;
			}
		}
	}
}
@media (max-width: 1023px) {
	html:not(.dlink) #fixedBottomArea {
		position: fixed;
		z-index: 103;
		display: flex;
		flex-direction: column;
		align-items: end;
		width: 100%;
		bottom: 0;
		right: 0;
		gap: 2px;
		pointer-events: none;
		& > * {
			pointer-events: auto;
			&:not(#audioplayer-container):not(#content-sticky-place):not(#upload-manager-container) {
				position: relative!important;
				right: 15px!important;
				bottom: 0!important;
				margin-bottom: 16px;
			}
		}

		& > #upload-manager-container,
		& > #audioplayer-container,
		& > #content-sticky-place {
			width: 100%;
		}

		& #mobile-upload-button-container,
		& #chatbase-bubble-button {
			transition: opacity .4s linear;
		}
	}
}

html.dlink:not(.mob):not(.mobile) #fixedBottomArea {
	position: fixed;
	bottom: 3px;
	right: 15px;
	z-index: 110;
	display: flex;
	flex-direction: column;
	align-items: end;
	row-gap: 16px;
	pointer-events: none;

	& > * {
		pointer-events: auto;
	}
}
html.dlink.mob #fixedBottomArea,
html.dlink.mobile #fixedBottomArea {
	position: fixed;
	z-index: 110;
	display: flex;
	flex-direction: column;
	align-items: end;
	width: 100%;
	bottom: 0;
	right: 0;
	row-gap: 0px;
	pointer-events: none;
	& > * {
		pointer-events: auto;
	}

	& > #upload-manager-container,
	& > #audioplayer-container {
		width: 100%;
	}
}

#ui-datepicker-div {
	@media (max-width: 400px) or (max-height: 600px) {
		position: fixed!important;
		top: 0!important;
		left: 0!important;
		width: 100%!important;
		height: 100%!important;
		box-sizing: border-box!important;
		padding: 40px 0 0 0!important;
	}

	border-radius: var(--radius-minimum);
	padding: 8px 12px;
	border: 0.5px rgba(0, 0, 0, 0.25);
	background: #FFF;
	box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.15);

	.ui-datepicker-header {
		padding: 4px 12px 4px 4px;
		background: none;
		display: flex;
		align-items: center;

		.ui-datepicker-title {
			padding: 10px 4px 10px 8px;
			text-align: left;
			margin: 0;

			color: #49454F;
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;
			letter-spacing: 0.1px;
		}
		.ui-datepicker-prev {
			top: 7px;
			left: auto;
			right: 60px;
			background: url('/img/navigate_before.svg') no-repeat center center;
			opacity: 1;
			width: 24px;
			height: 24px;
			min-width: auto;
			cursor: pointer;
		}
		.ui-datepicker-next {
			top: 7px;
			right: 12px;
			background: url('/img/navigate_next.svg') no-repeat center center;
			opacity: 1;
			width: 24px;
			height: 24px;
			min-width: auto;
			cursor: pointer;
		}
		.ui-corner-all.ui-state-disabled {
			opacity: 0.5;
		}
	}

	.ui-widget-time {
		padding: 0 var(--spacing-sm);
		select {
			text-align-last: center;
			text-align: center;
			border-radius: 88px;
			border: 1px solid var(--border-primary);
			background: var(--surface-secondary);
			width: 100%;
			height: 40px;
			padding: var(--spacing-none) var(--spacing-xs) var(--spacing-none) var(--spacing-xs);
			font-size: 14px;
			color: var(--text-primary);
			font-weight: 400;
			line-height: 20px;

			-moz-appearance:none;
			-webkit-appearance:none;
			appearance:none;
			&:focus{
				outline:none;
				border: 1px solid var(--color-primary-500);
			}
			option {
				text-align: center;
			}
		}
	}

	.ui-datepicker-calendar {
		th, td {
			background: #FFF!important;
			span {
				color: var(--text-secondary);
			}
			a {
				color: var(--text-primary);
			}
			span, a {
				box-sizing: border-box;
				border: 1px solid #FFF;
				width: 40px;
				height: 40px;
				min-width: auto;
				text-align: center;
				font-size: 12px;
				font-weight: 400;
				line-height: 38px;
				letter-spacing: 0.4px;
				vertical-align: middle;

				@media (max-width: 400px) or (max-height: 600px) {
					width: auto;
				}
			}
		}
		th {
			span {
				color: var(--text-primary);
			}
		}
		a.ui-state-default:not(.ui-state-active) {
			&:hover {
				border: 1px solid #FFF!important;
				color: var(--color-primary-500) !important;
				font-weight: 400;
			}
		}
		.ui-state-active {
			border-radius: 100px;
			border: 1px solid var(--color-primary-500)!important;
			background: #FFF!important;
			color: var(--color-primary-500)!important;
			text-align: center;
			font-size: 12px;
			font-weight: 400;
			line-height: 38px;
			letter-spacing: 0.4px;
		}

	}

	.ui-widget-content {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: row-reverse;
		gap: 8px;

		button {
			color: var(--color-primary-500);
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px;
			letter-spacing: 0.1px;
			background: inherit;
			border: none;
			float: none;
		}
	}
}
@media (max-width: 800px) {
	.setup_box {
		max-width: none;
		padding: 0 var(--spacing-md) 150px;
		box-sizing: border-box;

		.warning_msg {
			box-sizing: border-box;
			border-radius: var(--radius-minimum);
			border: 2px solid #fcb141;
			margin: var(--spacing-md) 0 var(--spacing-xl) 0;
			.msg {
				margin: 0
			}
		}

		.sep {
			display: none;
		}

		.form_wrap {
			display: flex;
			flex-direction: column-reverse;
			justify-content: center;
			align-items: start;
			gap: 28px;
		}

		.legend {
			margin: 0;
			width: 100%;
			strong {
				color: var(--text-primary);
				font-family: Roboto, sans-serif;
				font-size: 14px;
				font-weight: 600;
				line-height: 20px;
				padding: 0;
			}
			li {
				color: var(--text-primary);
				font-family: Roboto, sans-serif;
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
			}
		}

		.btn.create_crypto {
			margin: 24px 0 0;
			max-width: 340px;
			border-radius: var(--radius-minimum);
		}

		.input_box {
			position: relative;
			max-width: 340px;
			width: 100%;
			.pass_fill {
				right: 4px;
				top: 42px;
			}
			.cryptopass2.ok {
				background-position-x: 98%;
			}
			label {
				color: var(--text-primary);
				font-family: Roboto, sans-serif;
				font-size: 14px;
				font-weight: 600;
				line-height: 20px;
				padding: 0;
				margin-top: 8px;
				.strngt {
					color: #a5a5a5;
					font-family: Roboto, sans-serif;
					font-size: 14px;
					font-weight: 400;
					line-height: 20px;
					padding: 0;
				}
			}
			input {
				width: 100%;
				box-sizing: border-box;
			}
			.ps2_err, .hint_err {
				width: 100%;
				padding: 0;
				margin-top: 4px;
			}
		}
	}
}