html.label-coversafe {

    .leftline body {
        background: url('/ZCDNURL/img/coversafe/bg.png') repeat-y;
    }

    .header {
        background-color: rgb(193,201,52);
        .logo {
            padding: 10px 0 12px 20px;
            border-color: transparent;
        }
        .profile {
            color: #252525;
            border-color: transparent;
            .settings {
            background: url('/ZCDNURL/img/coversafe/settings.png') no-repeat;
            &.opn, &:hover {
                background: url('/ZCDNURL/img/coversafe/settings-opn.png') no-repeat;
            }
            }
        }
        a.profile {
            .settings {
                background: url('/ZCDNURL/img/coversafe/settings.png') no-repeat;
                &.opn, &:hover {
                    background: url('/ZCDNURL/img/coversafe/settings-opn.png') no-repeat;
                }
            }
        }
        .centr input {
            color: #989B58;
            border-radius: 2px;
            border: 1px solid #DEE28B;
            background-color: #DEE28B;
            border-radius: 2px 0px 0px 2px;
            &::-webkit-input-placeholder { /* WebKit browsers */
                color: #989B58;
            }
            &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
            color: #989B58;
            opacity:  1;
            }
            &::-moz-placeholder { /* Mozilla Firefox 19+ */
            color: #989B58;
            opacity:  1;
            }
            &:-ms-input-placeholder { /* Internet Explorer 10+ */
            color: #989B58;
            }

        }
    }

    .left {
        .menu {
            li {
                span {
                    background: #c1c934;
                    padding: 0 3px;
                    height: 15px;
                }
            }
        }
    }

    .badge-circle {
        border: 2px solid #c1c934;
    }

    /*** MOBILE ***/
    html.label-coversafe {
        .mobheader {
            background-color: #c1c934;
            .middle {
                padding-top: 2px;
                a { margin-left: -60px }
            }
            .gearplc { display: none }
        }

        .moblogo {
            background: url('/ZCDNURL/img/coversafe/mobile/logo.png') no-repeat;
            width: 168px;
            height: 30px;
        }


        .mobile_btn_share, .newfolder { background-color: #c1c934; }
        &.mob .newfolder, &.mob .newfolder:hover {
            padding: 0 27px;
        }
        .moblog .greenbut { background-color: #ffbf00; }
        #pcloud-faq-header { display: none }
        #pcloud-contact input[type=submit] {
            background-color: #c1c934;
            background-image: none!important;
        }

        .panelact { background-image: url('/ZCDNURL/img/coversafe/mobile/open-menu.png'); }

        .panel ul li { background-position: 15px 7px; }

        .panel ul li.root { background-image: url('/ZCDNURL/img/coversafe/mobile/my-coversafe.png'); background-size: 21px 23px;}
        .panel ul li.documents { background-image: url('/ZCDNURL/img/coversafe/mobile/documents.png'); background-size: 19px 23px; }
        .panel ul li.myshares { background-image: url('/ZCDNURL/img/coversafe/mobile/shares.png'); background-size: 19px 23px; }
        .panel ul li.feedback { background-image: url('/ZCDNURL/img/coversafe/mobile/feedback.png'); background-size: 23px 21px;}
        .panel ul li.uploader { background-image: url('/ZCDNURL/img/coversafe/mobile/upload.png'); background-size: 19px 18px;}

        .panel ul li.promopartners { background-image: url('/ZCDNURL/img/coversafe/mobile/partners-soon.png'); background-size: 23px 21px;}
        .panel ul li.send { background-image: url('/ZCDNURL/img/coversafe/mobile/send-soon.png'); background-size: 23px 19px;}
        .panel ul li.alert { background-image: url('/ZCDNURL/img/coversafe/mobile/alert-soon.png'); background-size: 20px 23px;}
        .panel ul li.logout { background-image: url('/ZCDNURL/img/coversafe/mobile/logout.png'); background-size: 23px 23px;}

        .panel ul li.root.active { background-image: url('/ZCDNURL/img/coversafe/mobile/my-coversafe-active.png'); }
        .panel ul li.documents.active { background-image: url('/ZCDNURL/img/coversafe/mobile/documents-active.png'); }
        .panel ul li.myshares.active { background-image: url('/ZCDNURL/img/coversafe/mobile/shares-active.png'); }
        .panel ul li.feedback.active { background-image: url('/ZCDNURL/img/coversafe/mobile/feedback-active.png'); }
        .panel ul li.uploader.active { background-image: url('/ZCDNURL/img/coversafe/mobile/upload-active.png'); background-size: 19px 18px;}

        .panel ul li.logout { background-image: url('/ZCDNURL/img/coversafe/mobile/logout.png'); }

        .panel ._mobile_space {
            width: 100%;
            background-color: #f5f5f5;
            &.mob-bottom {
                position: absolute;
                bottom: 0;
            }
            .space-quota {
                float: none;
                width: 100%;
                box-sizing: border-box;
            }
            .more-space { display: none }
        }


        @media (min--moz-device-pixel-ratio: 2),
        (-o-min-device-pixel-ratio: 2/1),
        (-webkit-min-device-pixel-ratio: 2),
        (min-device-pixel-ratio: 2) {
            .moblogo {
                background: url('/ZCDNURL/img/coversafe/mobile/logo@2x.png') no-repeat;
                background-size: 168px 30px;
                width: 168px;
                height: 30px;
                display: inline-block;
            }
            .panelact { background-image: url('/ZCDNURL/img/coversafe/mobile/open-menu@2x.png'); }

            .panel li.soon {
                color: #6b6b6e;
            }
            .panel li.soon:after {
                content: 'soon';
                font-size: 10px;
                font-weight: 300;
                text-transform: uppercase;
                display: inline-block;
                padding: 0 5px;
                height: 18px;
                line-height: 18px;
                margin-left: 8px;
                background-color: #c1c934;
                -moz-border-radius: 2px;
                -webkit-border-radius: 2px;
                border-radius: 2px;
                color: #fff;
            }
            .panel ul li.root { background-image: url('/ZCDNURL/img/coversafe/mobile/my-coversafe@2x.png'); }
            .panel ul li.documents { background-image: url('/ZCDNURL/img/coversafe/mobile/documents@2x.png'); }
            .panel ul li.myshares { background-image: url('/ZCDNURL/img/coversafe/mobile/shares@2x.png'); background-size: 19px 23px; }
            .panel ul li.feedback { background-image: url('/ZCDNURL/img/coversafe/mobile/feedback@2x.png'); }
            .panel ul li.uploader { background-image: url('/ZCDNURL/img/coversafe/mobile/upload@2x.png'); background-size: 19px 18px;}

            .panel ul li.promopartners { background-image: url('/ZCDNURL/img/coversafe/mobile/partners-soon@2x.png'); background-size: 23px 21px;}
            .panel ul li.send { background-image: url('/ZCDNURL/img/coversafe/mobile/send-soon@2x.png'); background-size: 23px 19px;}
            .panel ul li.alert { background-image: url('/ZCDNURL/img/coversafe/mobile/alert-soon@2x.png'); background-size: 20px 23px;}
            .panel ul li.logout { background-image: url('/ZCDNURL/img/coversafe/mobile/logout@2x.png'); }

            .panel ul li.root.active { background-image: url('/ZCDNURL/img/coversafe/mobile/my-coversafe-active@2x.png'); }
            .panel ul li.documents.active { background-image: url('/ZCDNURL/img/coversafe/mobile/documents-active@2x.png'); }
            .panel ul li.myshares.active { background-image: url('/ZCDNURL/img/coversafe/mobile/shares-active@2x.png'); }
            .panel ul li.feedback.active { background-image: url('/ZCDNURL/img/coversafe/mobile/feedback-active@2x.png'); }
            .panel ul li.uploader.active { background-image: url('/ZCDNURL/img/coversafe/mobile/upload-active@2x.png'); background-size: 19px 18px;}

        }
    }
    /*** MOBILE END ***/

    .label-coversafe {
        .publink-sprite.download {
            background: url("/ZCDNURL/img/coversafe/download.png") no-repeat scroll center top rgba(0, 0, 0, 0) !important;
            &:hover {
            background: url("/ZCDNURL/img/coversafe/download-hover.png") no-repeat scroll center top rgba(0, 0, 0, 0) !important; 
            }
        }
        .publink-sprite.settings {
            width: 19px;
            height: 19px;
            background: url("/img/coversafe/settings.png") top center;
        }

        .publink-sprite.settings-active, .publink-sprite.settings.opened {
            width: 19px;
            height: 19px;
            background: url("/img/coversafe/settings-opn.png") top center;
        }
        @media (min--moz-device-pixel-ratio: 2),
        (-o-min-device-pixel-ratio: 2/1),
        (-webkit-min-device-pixel-ratio: 2),
        (min-device-pixel-ratio: 2) {
            .publink-sprite.settings {
                width: 19px;
                height: 19px;
                background: url("/img/coversafe/settings.png") top center;
            }

            .publink-sprite.settings-active, .publink-sprite.settings.opened {
                width: 19px;
                height: 19px;
                background: url("/img/coversafe/settings-opn.png") top center;
            }    
        }

        .pcloud-logo {
            background: url('/ZCDNURL/img/coversafe/cover-logo.png') no-repeat;
            height: 32px;
            width: 170px;
        }
    }

    .button, .button:hover {
        background-color: #c1c934;
    }
    .upload_button, .cheader .controls .button.linebut {
        background-color: #FFBF00;
        &:hover {
            background-color:#F4B800;
            border-color:rgba(0,0,0,0.03);
            -moz-box-shadow:none;
            -webkit-box-shadow:none;
            box-shadow:none;
        }
        img {
            position: absolute;
            letter-spacing: -1px;
            left: 30px;
            margin-top: 9px;
        }
    }
    .button.facebook {
    background-color: #31499F;
    }
    .button.twitter {
    background: #2EBDE1;
    }
    div.cheader {
        div.fbreadcrumb, b {
        font-weight: normal;
        //  font-size: 22px;
        line-height: 38px;
        }
        div.fbreadcrumb {
            padding-top: 0;
        }
    }

    .left .menu hr:last-child {
        display: none;
    }
    .alerts a, .promo_partners a, .send a {
        color: #bbb !important;
        cursor: default;
        &:hover {
            color: #bbb !important;
        }
    }
    .newfolder, .newfolder:hover  {
        color: #272829;
        border-color: #e1e1e1;
        background-image: -moz-linear-gradient(50% 0% -90deg,rgb(255,255,255) 0%,rgb(247,247,247) 100%);
        background-image: -webkit-gradient(linear,50% 0%,50% 100%,color-stop(0, rgb(255,255,255)),color-stop(1, rgb(247,247,247)));
        background-image: -webkit-linear-gradient(-90deg,rgb(255,255,255) 0%,rgb(247,247,247) 100%);
        background-image: -o-linear-gradient(-90deg,rgb(255,255,255) 0%,rgb(247,247,247) 100%);

        -moz-box-shadow: 0px 1px 1px rgba(0,0,0,0.05);
        -webkit-box-shadow: 0px 1px 1px rgba(0,0,0,0.05);
        box-shadow: 0px 1px 1px rgba(0,0,0,0.05);

        padding: 0 27px 0 56px;

        span {
            background: url('/ZCDNURL/img/coversafe/new-folder.png') no-repeat 0 bottom !important;
            left: 10px;
        }
        &:hover {
            background-color:#f6f6f6;
            -moz-box-shadow:none;
            -webkit-box-shadow:none;
            box-shadow:none;
            background-image:none !important;
        }
    }
    .l-soon {
        font-size: 9px;
        font-weight: bold;
        text-transform: uppercase;
        display: inline-block;
        padding: 0;
        height: 15px;
        width: 28px;
        line-height: 16px;
        padding-left: 3px;
        padding-right: 3px;
        margin-left: 2px;
        background-color: #c1c934;
        -moz-border-radius: 1px;
        -webkit-border-radius: 1px;
        border-radius: 1px;
        color: #fff;
    }
    .root {
    font-size: 13px;
    font-weight: bold;
    }

    .spacen {
        border-top: 0;
        background: #fafafa;
        height: 15px;
    }
    .getmorespace, .getmorespace:hover {
        background-color: #C1C934 !important;
        border-color: rgba(0,0,0,0.02) !important;
    }

    input.logbut {
        background-color: #C1C934 !important;
    }
    .cheader .controls .button.linebut {
        position: relative;
        padding: 0 40px 0 49px !important;
        margin-right: 10px;
        img {
            position: absolute;
            letter-spacing: -1px;
            left: 10px;
            margin-top: 9px;
        }
    }
    .greenbut:hover {
        border-color: rgba(0,0,0,0.02);
    }
    .darkbut {
        background: #343536 !important;
    }
    .search-button {
        background: url('/ZCDNURL/img/coversafe/search-button.png') no-repeat center center !important;
        border-left-style: none !important;
        border-top-color: #fff !important;
        border-right-color: #fff !important;
        border-bottom-color: #fff !important;
        background-color: #fff !important;
        border-radius: 0px 2px 2px 0px !important;
    }
    .tabs .ctrl span.sel {
        border-top: 2px solid rgb(193,201,52);
    }
    .rginfo, .change_lang, .social, .and_int, .int_policy, span.comma, .puplink_mailto {
        display: none !important;
    }
    .hide-and {
        display: inline !important;
    }
    .lgrgfrm {
        width: 400px !important;
        float: none !important;
    }
    .register-fields input {
        width: 358px !important;
    }
    .reg-bottom .regbut {
        width: 368px !important;
    }
    .reg-bottom .realcopy {
        position: absolute;
        margin: 0;
        width: 960px;
    }
    .reg-bottom .copyright {
        margin: 75px auto 0 auto;
        border-top: 1px solid #F0F0F0;
        width: auto;
        display: table;
    }
    .reg-bottom .haveacc {
        width: 100%;
        position: relative;
        z-index: 100;
    }
    .lgrgfrm .greenbut.regbut {
        width: 368px;
    }
    .outsidebut, .outsidebut:hover {
        margin: 15px auto 30px auto !important;
        display: table !important;
        width: auto !important;
        min-width: 368px !important;
        background-color: #FFBF00 !important;
        border: 1px solid rgba(0,0,0,0.02) !important;
    }

    .mnnew .csortnew-ico {
        background: url('/ZCDNURL/img/coversafe/csort_new.png') no-repeat 0 17px transparent !important;
    }
    .mn-blue ul {
        border-top-color: #252525 !important;
    }
    .settings ul {
        display: none;
        position: absolute;
        top: 29px;
        right: 0;

        margin: 0;
        background: #ffffff url('/ZCDNURL/img/coversafe/tip.png') no-repeat -10px right !important;
    }
    .folderlist-ico {
        background: url('/ZCDNURL/img/coversafe/tplswitch-row.png') center 17px no-repeat !important;
        width: 20px;
    }
    div.headerunder div.gridmanage ul.templateswitch {
        padding-right: 5px;
    }
    div.headerunder div.gridmanage ul.templateswitch li.foldergrid {
        padding-left: 19px;
    }
    .cheader_up.mnheader {
        float: right;
        margin-top: 1px;
    }
    .modal-title, .uploadtitle, .minimizedtitle {
        background: #c1c934 !important;
        color: #252525 !important;
    }
    .modal .modal-close, .lightbox .modal-close {
        background: url('/ZCDNURL/img/coversafe/wclose.png');
    }
    .modal .processing .information, .modal .loadcenter, .lightbox .loadcenter {
        background: #fefefe url('/ZCDNURL/img/coversafe/processing.gif') no-repeat center center !important;
    }
    .upload .status .progress .bar, .pupload .pupstatus .progress .bar {
        background: url('/ZCDNURL/img/coversafe/download-green.png') repeat-x top left;
    }
    .centertabs .tbs span.activ, .centertabs .tbs a.activ {
        border-bottom: 2px solid #c1c934;
    }
    .setbut {
        background-color: #c1c934 !important;  
        border: 1px solid rgba(0,0,0,0.02) !important;
    }
    .darkgraybut {
    background: #343536 !important;
    }
    .darkbut {
    background: #343536 !important;
    }
    .redbut {
    background: #FC354C !important;
    }
    .mnnew.top.mn-blue .tip {
        border-top-color: #252525 !important;
    }
    .tip {
        border-bottom-color: #252525 !important;
    }
    .pcloud-accordion #about_us {
        display: none !important;
    }
    .label-coversafe {

        .pub-button {
            display: inline-block;
            text-transform: none;
            
            line-height: 14px;
            height: 38px;

            font-weight: 300;
            font-size: 14px;

            cursor: pointer;
            background: #FFBF00;
            border: 1px solid rgba(0,0,0,0.02);
            -moz-border-radius: 3px;
            -webkit-border-radius: 3px;
            border-radius: 3px;

            padding: 9px 10px;
            color: #fff;

            -moz-box-shadow: 0px 1px 0px rgba(0,0,0,0.07);
            -webkit-box-shadow: 0px 1px 0px rgba(0,0,0,0.07);
            box-shadow: 0px 1px 0px rgba(0,0,0,0.07);
        }
        .small-mobile .pub-button {
            display: block;
            line-height: 19px;
            width: 100%;
        }
        .pub-button:hover {
            background: #F4B800;
            border: 1px solid rgba(0, 0, 0, 0.03);
        }
        #fileinfo .gray {
            color: #252525;
        }
    }
    .notify-img {
        background: url('/ZCDNURL/img/coversafe/bell.png') no-repeat;
    }
}